import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './navbar';
import './App.css'; // Make sure Tailwind CSS is imported in your main CSS file
import Home from './home';
import Contact from './contact';
import About from './about';
import Services from './services';
import BlogList from './BlogList'; // Import the BlogList component
import BlogDetail from './BlogDetail'; // Import the BlogDetail component
import Appointment from './appointment'; // Assuming you have an appointment component
import useScrollToTop from './useScrollToTop';
import ScrollToTop from './ScrollToTop';
function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <ScrollToTop />
        <div className="pt-16"> {/* Add top padding to the main content */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/blog" element={<BlogList />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/appointment" element={<Contact />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
