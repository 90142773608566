import React from 'react';
import footerLogo from "./Assets/footerLogo.png";
import instaIcon from "./Assets/InstagramIcon.png";
import linkedinIcon from "./Assets/linkedinIcon.png";
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation(); // Using the translation hook

    return (
        <footer className="bg-teal-900 text-white py-8">
            <div className="container mx-auto flex justify-between items-center w-4/5 text-left max-md:flex-col">
                {/* Left Section */}
                <div className="flex flex-row w-1/3 justify-between max-md:w-full">
                    <div className="flex flex-col space-y-2">
                        <img src={footerLogo} alt="Footer Logo" className='w-[57px] h-[57px]' />
                    </div>
                    <div className="flex space-x-12">
                        <ul className="space-y-2">
                            <h3 className="font-bold">{t('menu')}</h3>
                            <li><a href="/" className="hover:underline">{t('home')}</a></li>
                            <li><a href="/about" className="hover:underline">{t('about')}</a></li>
                            <li><a href="/services" className="hover:underline">{t('services')}</a></li>
                            <li><a href="/blog" className="hover:underline">{t('blog')}</a></li>
                            <li><a href="/contact" className="hover:underline">{t('contact')}</a></li>
                        </ul>
                    </div>
                    <div className="flex space-x-12">
                        <ul className="space-y-2">
                            <h3 className="font-bold">{t('utilities')}</h3>
                            <li><a href="/terms" className="hover:underline">{t('termsPolicies')}</a></li>
                            <li><a href="/privacy" className="hover:underline">{t('privacyPolicy')}</a></li>
                            <div className='flex flex-row gap-1'>
                                <img src={linkedinIcon} alt="LinkedIn" className='w-[32px] h-[32px]' />
                                <img
                                    src={instaIcon}
                                    alt="Instagram"
                                    className="w-[32px] h-[32px]"
                                    onClick={() => window.open("https://www.instagram.com/medimetricminds?igsh=N21ueWUwaXExYnoy&utm_source=qr", "_blank")}
                                />
                            </div>
                        </ul>
                    </div>
                </div>
                {/* Right Section */}
                <div className="flex flex-col items-start text-left max-md:items-center max-md:text-center max-md:mt-[5vh] max-md:gap-4">
                    <p className="font-bold text-[48px] max-md:text-[20px]">
                        {t('getInTouch')} <br />
                        {t('contactUsToday')}
                    </p>
                    <a href="/contact" className="inline-block bg-white text-teal-900 font-semibold py-3 px-5 rounded-full hover:bg-gray-200">
                        {t('contactUs')}
                    </a>
                </div>
            </div>
            <div className="w-4/5 container mx-auto mt-8 flex justify-between items-center border-t border-gray-600 pt-4">
                <p className="text-sm">{t('footerNote')}</p>
                <div className="flex space-x-4">
                    <a href="#" className="hover:text-gray-400"><i className="fab fa-xing"></i></a>
                    <a href="#" className="hover:text-gray-400"><i className="fab fa-linkedin"></i></a>
                    <a href="#" className="hover:text-gray-400"><i className="fab fa-facebook"></i></a>
                    <a href="#" className="hover:text-gray-400"><i className="fab fa-instagram"></i></a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
