import React from 'react';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="container w-4/5 mx-auto px-4 py-12">
                <div className="text-center mb-12">
                    <h1 className="text-4xl text-primary">{t('Schedule a Consultation')}</h1>
                </div>
                <div className="flex flex-col gap-5 md:flex-row items-start md:items-start justify-between">
                    <div className="w-full md:w-1/3 text-left ">
                        <h2 className="text-2xl text-primary font-bold mb-4">{t('Online Face-to-Face Consultation')}</h2>
                        <p className="mb-6 text-[#9EB1B0] text-[20px]">
                            {t('If you do not know where and how to start, if you are at the very beginning of your research, you can get online face-to-face support from our experts in determining the design and variables of the study and creating your database. Apart from these, our experts will be with you for any questions you may have or issues that need to be resolved. To do this, you can make an appointment for an online consultation with our experts at a time that suits your calendar.')}
                        </p>
                        <div className="flex space-x-4 mb-6">
                            <a href="#" className="text-gray-600 hover:text-gray-800">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="#" className="text-gray-600 hover:text-gray-800">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="#" className="text-gray-600 hover:text-gray-800">
                                <i className="fab fa-facebook"></i>
                            </a>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 mt-8 md:mt-0">
                        <form className="bg-[#F3F7FA] shadow-md rounded-lg p-8 text-left">
                            <div className="mb-4">
                                <label className="block text-primary text-sm font-bold mb-2" htmlFor="firstName">
                                    {t('First name*')}
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="firstName"
                                    type="text"
                                    placeholder={t('First name')}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-primary text-sm font-bold mb-2" htmlFor="lastName">
                                    {t('Last name*')}
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-primary leading-tight focus:outline-none focus:shadow-outline"
                                    id="lastName"
                                    type="text"
                                    placeholder={t('Last name')}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-primary text-sm font-bold mb-2" htmlFor="email">
                                    {t('Email*')}
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-primary leading-tight focus:outline-none focus:shadow-outline"
                                    id="email"
                                    type="email"
                                    placeholder={t('Email')}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-primary text-sm font-bold mb-2" htmlFor="phone">
                                    {t('Phone number*')}
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-primary leading-tight focus:outline-none focus:shadow-outline"
                                    id="phone"
                                    type="tel"
                                    placeholder={t('Phone number')}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-primary text-sm font-bold mb-2" htmlFor="file">
                                    {t('Add File*')}
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-primary leading-tight focus:outline-none focus:shadow-outline text-[12px]"
                                    id="file"
                                    type="file"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-primary text-sm font-bold mb-2" htmlFor="message">
                                    {t('Message*')}
                                </label>
                                <textarea
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-primary leading-tight focus:outline-none focus:shadow-outline"
                                    id="message"
                                    placeholder={t('Please briefly describe the topic you would like to discuss with the expert')}
                                    rows="5"
                                    required
                                ></textarea>
                            </div>
                            <div className="flex items-center justify-center">
                                <button
                                    className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="submit"
                                >
                                    {t('Book an Appointment')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contact;
