import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logoIcon from "./Assets/logoIcon.png";

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const toggleLanguage = () => {
        const newLanguage = i18n.language === 'en' ? 'tr' : 'en';
        i18n.changeLanguage(newLanguage);
    };

    return (
        <nav className="bg-white shadow-lg w-4/5 mx-auto max-md:w-11/12 md:rounded-full max-md:rounded-[36px] border border-gray-300 fixed top-2 left-1/2 transform -translate-x-1/2 z-50">
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    <div className="flex">
                        <div className="shrink-0 flex items-center">
                            <img src={logoIcon} className="h-8 w-8" alt="Logo" />
                            <div className="flex flex-col items-start">
                                <span className="ml-2 font-bold text-sm">{t('companyName')}</span>
                                <span className="ml-2 text-xs text-gray-500">{t('tagline')}</span>
                            </div>
                        </div>
                        <div className="hidden md:ml-6 md:flex md:space-x-8">
                            <Link to="/" className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900">
                                {t('home')}
                            </Link>
                            <Link to="/about" className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900">
                                {t('about')}
                            </Link>
                            <Link to="/services" className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900">
                                {t('services')}
                            </Link>
                            <Link to="/blog" className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900">
                                {t('blog')}
                            </Link>
                            <Link to="/contact" className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900">
                                {t('contact')}
                            </Link>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <Link
                            to="/appointment"
                            className="hidden md:inline-flex rounded-full items-center px-4 py-2 border border-transparent text-sm font-medium text-white bg-teal-500 hover:bg-teal-600"
                        >
                            {t('freeAppointment')}
                            <svg className="ml-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    fillRule="evenodd"
                                    d="M12.293 3.293a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L14 6.414V15a1 1 0 11-2 0V6.414L9.707 9.707a1 1 0 11-1.414-1.414l5-5z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </Link>
                        <button
                            onClick={toggleMenu}
                            className="md:hidden inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                        >
                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path
                                    className={!isOpen ? 'block' : 'hidden'}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                                <path
                                    className={isOpen ? 'block' : 'hidden'}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <div className="ml-4">
                            <button onClick={toggleLanguage} className="text-sm font-medium text-gray-900">
                                {i18n.language === 'en' ? 'TR' : 'EN'}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} px-2 pt-2 pb-3 space-y-1`}>
                <Link to="/" onClick={closeMenu} className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                    {t('home')}
                </Link>
                <Link to="/about" onClick={closeMenu} className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                    {t('about')}
                </Link>
                <Link to="/services" onClick={closeMenu} className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                    {t('services')}
                </Link>
                <Link to="/blog" onClick={closeMenu} className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                    {t('blog')}
                </Link>
                <Link to="/contact" onClick={closeMenu} className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                    {t('contact')}
                </Link>
                <Link
                    to="/appointment"
                    onClick={closeMenu}
                    className="block w-full text-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-teal-500 hover:bg-teal-600"
                >
                    {t('freeAppointment')}
                </Link>
            </div>
        </nav>
    );
};

export default Navbar;
