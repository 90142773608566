import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            companyName: "MediMetricMinds",
            home: "Home",
            about: "About",
            services: "Services",
            blog: "Blog",
            contact: "Contact",
            tagline: "BIOSTATISTICS CONSULTANCY",
            // General
            learnMore: "Learn More",
            freeAppointment: "Free Appointment",
            easyOnlineAppointment: "Easy Online Appointment",
            aboutUs: "ABOUT US",
            enhanceResearch: "Enhance Your Research",
            enhanceResearchDescription: "Our team can support you with statistical analysis and other services to improve your research manuscript. Our team comprises not only statisticians but also clinicians and surgeons, who are enthusiastic in statistical analysis and academic works. This diversity of expertise in our team usually holds the advantage of understanding what is wanted and what to do without being requested.",
            ourReferences: "OUR REFERENCES",
            trustedByResearchers: "Trusted by Leading Researchers Worldwide",
            servicesTitle: "SERVICES",
            servicesDescription: "The process starts with what you have in hands or at what stage you have reached in your research. We have a wide variety of options to help you complete your research project. The services that we can support you are as follows:",
            moreServices: "More Services",
            testimonials: "Testimonials",
            blogDescription: "Insights and Innovations: Our Research Blog",
            moreBlogs: "More Blogs",
            faq: "FAQ",
            faqDescription: "Answers to Your Questions",
            unlockPotential: "Unlock Your Potential with Our Customizable Solutions",
            dedicationText: "We know who you are. As residents, clinicians, or surgeons, you're dedicated to your patients and to advancing your academic career. That's where we come in.",
            balancingText: "We understand that balancing work and academic responsibilities can be challenging. That's why we offer flexible solutions to help you achieve your goals without sacrificing your valuable time. Let us help you succeed in your academic pursuits while you focus on your patients, career and other priorities.",

            // Services
            workPlanTitle: "Work Plan, Work List",
            workPlanDescription: "Create a smooth and efficient work plan for your scientific research project with our expert guidance. Schedule an online face-to-face discussion to get started.",
            graphicsTablesTitle: "Graphics and Tables",
            graphicsTablesDescription: "Simplify and clarify your research results with our easy-to-understand tables and content-rich graphics. Our software enhances the readability and presentation of your article, making your findings clear and engaging.",
            statisticalAnalysisTitle: "Statistical Analysis",
            statisticalAnalysisDescription: "Is your database ready and your analysis plan set? Get a quote for our statistical analysis service by filling out a short form or talking to our expert online. Upload your databases, and we'll respond within 24 hours.",

            // Testimonials
            testimonial1: "The MediMetricMinds team, from whom I received statistical analysis support for my research, wrote a very fast and effective edit and response letter based on the statistical analysis criticisms made by the journal referees. Finally, my article was accepted and published. I would like to thank MediMetricMinds for their support.",
            testimonial2: "I didn't have much experience in clinical research. In a single face-to-face online meeting with MediMetricMinds expert team, we determined all the research steps I needed to do. The research process is long and laborious, but with a good work plan, the process went more smoothly. I would like to thank the Medimetricminds team.",
            testimonial3: "They delivered the graphic images of my work in a very short time, in a simple and understandable way. Thank you...",

            // FAQ
            faqQuestion1: "How Does the Process Work?",
            faqAnswer1: "In the initial stage, we arrange a face-to-face meeting with our experts on a common platform (Zoom, Google Meet, etc.). During this first meeting, our experts determine the details of your requirements and needs, and together we create a roadmap. After the meeting, you will be informed via email about the tasks to be performed, pricing, and scheduling as agreed upon by you and our experts. Once you approve the task list, the process begins.",
            faqQuestion2: "I want to write my medical manuscript. How can you assist me?",
            faqAnswer2: "Our experts can guide you through the entire process of writing your medical manuscript, from structuring your ideas to polishing the final draft for submission.",
            faqQuestion3: "I need to produce articles for my academic career, but I don't know where to start. How can you assist me?",
            faqAnswer3: "We provide comprehensive support for producing academic articles, including topic selection, research design, data analysis, and manuscript writing.",
            faqQuestion4: "Can you guarantee that my article will be published in a good journal with the services I receive from you?",
            faqAnswer4: "While we cannot guarantee publication, our experts provide high-quality services designed to significantly improve your manuscript's chances of acceptance in reputable journals.",
            faqQuestion5: "I received statistical services from you, and my article received criticism from the journal. How can you assist me?",
            faqAnswer5: "Our experts can help you address the criticism by revising your statistical analyses, providing additional analyses if necessary, and guiding you on how to respond to reviewers' comments.",
            faqQuestion6: "I want to obtain statistical services from you for my scientific research, but I don't know how to collect my data. What can I do?",
            faqAnswer6: "We can assist you in designing your data collection process, including creating data collection instruments and providing guidance on the best practices for collecting high-quality data.",
            faqQuestion7: "I've received statistical consulting from you, but I'm unsure how to format the results for my article. How can you assist me?",
            faqAnswer7: "Our experts can help you format your results according to the guidelines of your target journal and ensure that they are presented clearly and effectively.",
            faqQuestion8: "What is Visual Abstract?",
            faqAnswer8: "A visual abstract is a graphical summary of the main findings of your research. It helps readers quickly understand the key points of your article.",
            faqQuestion9: "The journal requested a 'Cover Letter' for my article. What is a 'Cover Letter'?",
            faqAnswer9: "A cover letter is a document that you submit along with your manuscript. It introduces your manuscript to the journal editor and explains why your work is important and suitable for the journal.",
            faqQuestion10: "What is Title Page?",
            faqAnswer10: "A title page is the first page of your manuscript. It includes the title of your manuscript, the names and affiliations of the authors, and any acknowledgments or disclaimers.",
            faqQuestion11: "How are the prices for your services determined?",
            faqAnswer11: "The prices for our services are determined based on the scope of your project and your specific needs. A customized quote will be provided, outlining the pricing details after your project requirements are assessed.",
            faqQuestion12: "How can I make payments?",
            faqAnswer12: "Payments can be made via bank transfer or EFT to the account numbers provided after the quotation. An invoice will be issued and sent to you once the payment has been processed.",
            // Footer
            menu: "Menu",
            utilities: "Utilities",
            termsPolicies: "Terms & Policies",
            privacyPolicy: "Privacy Policy",
            getInTouch: "Get in Touch:",
            contactUsToday: "Contact Us Today!",
            contactUs: "Contact Us",
            footerNote: "© 2024 MediMetricMinds. All rights reserved",

            //ABOUT US 
            "discoverOurStory": "Discover Our Story",
            "MediMetricMinds operates to support academic research and clinical trials. Our mission is to provide quality, flexible, and innovative solutions to professionals in the academic and clinical fields to facilitate their research processes and optimize their outcomes.": "MediMetricMinds operates to support academic research and clinical trials. Our mission is to provide quality, flexible, and innovative solutions to professionals in the academic and clinical fields to facilitate their research processes and optimize their outcomes.",
            "Consultant": "Consultant",
            "Projects": "Projects",
            "Published in international SCI and SSCI journals": "Published in international SCI and SSCI journals",
            "Statistical Revisions": "Statistical Revisions",
            "Published in local journals": "Published in local journals",
            "Smiling Faces 😊": "Smiling Faces 😊",
            "Mean Data Return": "Mean Data Return",
            "Days": "Days",
            "Values": "Values",
            "Customer Satisfaction": "Customer Satisfaction",
            "We strive to understand our customers' needs and meet their expectations.": "We strive to understand our customers' needs and meet their expectations.",
            "Quality": "Quality",
            "Adding value to our customers by providing services at the highest standards.": "Adding value to our customers by providing services at the highest standards.",
            "Flexibility": "Flexibility",
            "Offering quick and flexible solutions to meet the changing needs of our customers.": "Offering quick and flexible solutions to meet the changing needs of our customers.",
            "Innovation": "Innovation",
            "Supporting our customers' success by continuously developing innovative solutions.": "Supporting our customers' success by continuously developing innovative solutions.",
            "What We Do?": "What We Do?",
            "MediMetricMinds offers a variety of services to support academic research and clinical studies:": "MediMetricMinds offers a variety of services to support academic research and clinical studies:",
            "Online Consulting": "Online Consulting",
            "We provide expert consulting services for clients who need guidance or are starting their research process.": "We provide expert consulting services for clients who need guidance or are starting their research process.",
            "Statistical Analysis and Writing Services": "Statistical Analysis and Writing Services",
            "We offer expert support from data analysis to writing results.": "We offer expert support from data analysis to writing results.",
            "Graphic and Table Creation": "Graphic and Table Creation",
            "We help present research findings more effectively by visualizing them through graphics and tables.": "We help present research findings more effectively by visualizing them through graphics and tables.",
            "Plagiarism Check": "Plagiarism Check",
            "We provide professional plagiarism checking services to ensure the originality of articles.": "We provide professional plagiarism checking services to ensure the originality of articles.",
            "Reference Management": "Reference Management",
            "We organize and update article references using EndNote.": "We organize and update article references using EndNote.",
            "Medimetricminds works with an expert team to provide the best service to our clients. Our diverse specialists, including statisticians, clinical experts, and writers, come together to offer customized solutions tailored to our clients' needs.": "Medimetricminds works with an expert team to provide the best service to our clients. Our diverse specialists, including statisticians, clinical experts, and writers, come together to offer customized solutions tailored to our clients' needs.",
            "By working with us, you can take the right steps to achieve your academic goals and use your time most efficiently. Feel free to contact us; we would be delighted to discuss how we can assist you.": "By working with us, you can take the right steps to achieve your academic goals and use your time most efficiently. Feel free to contact us; we would be delighted to discuss how we can assist you.",
            "VISION & MISSION": "VISION & MISSION",
            "Empowering Academic Excellence": "Empowering Academic Excellence",
            "To assist our clients in achieving their academic goals, we offer statistical analysis, data management, and writing services, enabling them to utilize their time and resources most efficiently.": "To assist our clients in achieving their academic goals, we offer statistical analysis, data management, and writing services, enabling them to utilize their time and resources most efficiently.",
            "By providing the best services in the industry and contributing to scientific innovations in the academic world, we aim to be pioneers and earn the trust of our customers.": "By providing the best services in the industry and contributing to scientific innovations in the academic world, we aim to be pioneers and earn the trust of our customers.",
            "TEAM": "TEAM",
            "Meet the Experts": "Meet the Experts",
            "Lead Consultant": "Lead Consultant",
            "Congenital Cardiac Surgeon": "Congenital Cardiac Surgeon",
            "General Manager": "General Manager",
            "Operational Manager": "Operational Manager",
            "Organizational Team Leader": "Organizational Team Leader",
            "OUR POLICIES": "OUR POLICIES",
            "Ensuring Excellence": "Ensuring Excellence",
            "To ensure customer satisfaction and establish a reliable collaborative environment, MediMetricMinds adopts strict privacy policies and business ethics principles. By maintaining the highest quality standards, we continually review and improve our services.": "To ensure customer satisfaction and establish a reliable collaborative environment, MediMetricMinds adopts strict privacy policies and business ethics principles. By maintaining the highest quality standards, we continually review and improve our services.",
            "With a flexible refund and cancellation policy, we aim to maximize customer satisfaction. Through strong collaboration and effective communication with our clients, we aim to best meet their needs. These policies reflect the core values of the MediMetricMinds team and our commitment to providing reliable and professional service to our customers.": "With a flexible refund and cancellation policy, we aim to maximize customer satisfaction. Through strong collaboration and effective communication with our clients, we aim to best meet their needs. These policies reflect the core values of the MediMetricMinds team and our commitment to providing reliable and professional service to our customers.",

            //SERVICES PAGE 
            "How We Can Help You": "How We Can Help You",
            "Let's Begin": "Let's Begin",
            "Work Plan, Work List": "Work Plan, Work List",
            "We can help you create a work plan and work list for your scientific research project, which is often neglected but ensures that the work items flow smoothly throughout the study. Make your appointment and simply create your work plan with our expert guidance via an online face-to-face discussion.": "We can help you create a work plan and work list for your scientific research project, which is often neglected but ensures that the work items flow smoothly throughout the study. Make your appointment and simply create your work plan with our expert guidance via an online face-to-face discussion.",
            "Statistical Analysis": "Statistical Analysis",
            "Did you say that your database is ready and the statistical analysis to be applied has been determined? Congratulations! You can get a price and working time quote for statistical analysis services. To do this, we will ask you to fill out a form that we have prepared for you to briefly inform our expert about the purpose of your research along with other relevant information. If you do not want to fill out the form, you can inform our expert about your research through an online conversation. Once you upload your database to the system along with the form, we will get back to you within 24 hours at the latest.": "Did you say that your database is ready and the statistical analysis to be applied has been determined? Congratulations! You can get a price and working time quote for statistical analysis services. To do this, we will ask you to fill out a form that we have prepared for you to briefly inform our expert about the purpose of your research along with other relevant information. If you do not want to fill out the form, you can inform our expert about your research through an online conversation. Once you upload your database to the system along with the form, we will get back to you within 24 hours at the latest.",
            "Graphics and Tables": "Graphics and Tables",
            "The presentation of the results of any research is in need to be simplified, summarized, and be much more easy to be understood with tables and graphics. This work increases the readability and understandability of your article with our easy-to-understand and simplified tables. With our infrastructure software, we create content-rich graphics that increase the clarity and presentability of your article.": "The presentation of the results of any research is in need to be simplified, summarized, and be much more easy to be understood with tables and graphics. This work increases the readability and understandability of your article with our easy-to-understand and simplified tables. With our infrastructure software, we create content-rich graphics that increase the clarity and presentability of your article.",
            "Visual/Graphical Abstract": "Visual/Graphical Abstract",
            "Many international and respected medical journals now demand the creation of a visual/graphical abstract that summarizes the method and content of scientific research as well as important findings that contribute to the literature and ensures that the new understanding is shared with the reader at first glance. You can increase the interest of your article using a content-rich visual/graphical abstract that we can create for you.": "Many international and respected medical journals now demand the creation of a visual/graphical abstract that summarizes the method and content of scientific research as well as important findings that contribute to the literature and ensures that the new understanding is shared with the reader at first glance. You can increase the interest of your article using a content-rich visual/graphical abstract that we can create for you.",
            "Statistical Analysis and Results Section": "Statistical Analysis and Results Section",
            "Upon your request, we can write the 'results' section of your article and the 'statistical analysis' section of the materials and methods in line with your instructions, in mutual communication. The expert who will write the article will not only be a native English speaker but also an expert who has particular knowledge of the research topic and terminological jargon.": "Upon your request, we can write the 'results' section of your article and the 'statistical analysis' section of the materials and methods in line with your instructions, in mutual communication. The expert who will write the article will not only be a native English speaker but also an expert who has particular knowledge of the research topic and terminological jargon.",
            "Citation/References": "Citation/References",
            "During the article writing process, keeping minute-to-minute updated references in the text and in the references section simultaneously is not only a time-consuming but also a tiring process. At this stage, we want you to deal with the details of the content of your references and to decide which references to use and which not to use. Let us take care of the references for you using the EndNote.": "During the article writing process, keeping minute-to-minute updated references in the text and in the references section simultaneously is not only a time-consuming but also a tiring process. At this stage, we want you to deal with the details of the content of your references and to decide which references to use and which not to use. Let us take care of the references for you using the EndNote.",
            "Pre-Submission Peer-Review": "Pre-Submission Peer-Review",
            "Do you think the final version of your article is okay? Critical peer review before submitting your article to the journal is called pre-submission peer review and helps you pass the evaluations of the article with less damage by the referees determined by the journal. Our experts who support this issue are familiar with the perspective of journal referees.": "Do you think the final version of your article is okay? Critical peer review before submitting your article to the journal is called pre-submission peer review and helps you pass the evaluations of the article with less damage by the referees determined by the journal. Our experts who support this issue are familiar with the perspective of journal referees.",
            "Edit/Submit": "Edit/Submit",
            "We feel and know that you are in a very busy work tempo. We can edit your completed article according to the submission guideline of the target journal you want to submit and submit it to the journal on your behalf. Just simply make a request from us.": "We feel and know that you are in a very busy work tempo. We can edit your completed article according to the submission guideline of the target journal you want to submit and submit it to the journal on your behalf. Just simply make a request from us.",
            "Title Page / Cover Letter": "Title Page / Cover Letter",
            "Each article requires a title page and cover letter when being sent to a journal. The title page is requested from the authors in order to ensure that the referees evaluate your article in a healthy and impartial manner. It is aimed to hide in which center and by whom the research was conducted throughout your article text. The 'cover letter' is requested to briefly inform the journal editor that your research findings are not in the process of being evaluated simultaneously by another journal and the important points that your research findings contribute to science. These services are provided on your behalf by our experts.": "Each article requires a title page and cover letter when being sent to a journal. The title page is requested from the authors in order to ensure that the referees evaluate your article in a healthy and impartial manner. It is aimed to hide in which center and by whom the research was conducted throughout your article text. The 'cover letter' is requested to briefly inform the journal editor that your research findings are not in the process of being evaluated simultaneously by another journal and the important points that your research findings contribute to science. These services are provided on your behalf by our experts.",
            "Plagiarism Check": "Plagiarism Check",
            "Today, many journals and publishers use plagiarism detection software to prevent plagiarism. The similarity index obtained as a result of this check is used as a criterion, and many publishers require the similarity index of the articles they wish to publish to be below 20%. We want to let you know that we are here to support you in this matter. We perform plagiarism checks with multiple licensed software and provide the results to you as a report.": "Today, many journals and publishers use plagiarism detection software to prevent plagiarism. The similarity index obtained as a result of this check is used as a criterion, and many publishers require the similarity index of the articles they wish to publish to be below 20%. We want to let you know that we are here to support you in this matter. We perform plagiarism checks with multiple licensed software and provide the results to you as a report.",
            "Similarity Index Reduction": "Similarity Index Reduction",
            "Did your article's similarity index exceed 20% as a result of the plagiarism check? If so, how about using our SIR service to reduce your article's similarity index below 20%?": "Did your article's similarity index exceed 20% as a result of the plagiarism check? If so, how about using our SIR service to reduce your article's similarity index below 20%?",
            "Translation": "Translation",
            "Writing your article in your native language other than English means expressing what you want to convey more effectively. We would like to support you in this matter. You write your article in your own language, and we will translate it into English with expert native translators.": "Writing your article in your native language other than English means expressing what you want to convey more effectively. We would like to support you in this matter. You write your article in your own language, and we will translate it into English with expert native translators.",
            "Proofreading": "Proofreading",
            "Did you write your article in English and want to undergo a comprehensive anatomical and expressionist review? We can support you with our proofreading service in this matter.": "Did you write your article in English and want to undergo a comprehensive anatomical and expressionist review? We can support you with our proofreading service in this matter.",
            "English Support & IELTS Courses": "English Support & IELTS Courses",
            "As much as the English used in daily life, the English language skills you will use in your academic life are also very important. Every language course has its unique focus areas. Preparing for English proficiency exams with our team will have a positive impact on your academic life. Please contact us for specially prepared free introductory seminars.": "As much as the English used in daily life, the English language skills you will use in your academic life are also very important. Every language course has its unique focus areas. Preparing for English proficiency exams with our team will have a positive impact on your academic life. Please contact us for specially prepared free introductory seminars.",




        }
    },
    tr: {
        translation: {
            companyName: "MediMetricMinds",
            home: "Ana Sayfa",
            about: "Hakkımızda",
            services: "Hizmetlerimiz",
            blog: "Blog",
            contact: "İletişim",
            tagline: "BİYOİSTATİSTİK DANIŞMANLIK",
            // General
            learnMore: "Daha Fazla Bilgi Edinin",
            freeAppointment: "Ücretsiz Randevu",
            easyOnlineAppointment: "Kolay Online Randevu",
            aboutUs: "HAKKIMIZDA",
            enhanceResearch: "Araştırmanızı Geliştirin",
            enhanceResearchDescription: "Ekibimiz, araştırma makalenizi geliştirmek için size istatistiksel analiz ve diğer hizmetlerle destek olabilir. Ekibimizde sadece istatistikçiler değil, aynı zamanda istatistiksel analiz ve akademik çalışmalara ilgi duyan klinisyenler ve cerrahlar da bulunmaktadır. Ekibimizdeki bu uzmanlık çeşitliliği, istenilenleri talep edilmeden anlama avantajını genellikle beraberinde getirir.",
            ourReferences: "REFERANSLARIMIZ",
            trustedByResearchers: "Dünya Çapında Lider Araştırmacılar Tarafından Güvenilen",
            servicesTitle: "HİZMETLER",
            servicesDescription: "Süreç, elinizde ne olduğuyla veya araştırmanızda hangi aşamaya geldiğinizle başlar. Araştırma projenizi tamamlamanıza yardımcı olacak çok çeşitli seçeneklerimiz bulunmaktadır. Size destek verebileceğimiz hizmetler şunlardır:",
            moreServices: "Daha Fazla Hizmet",
            testimonials: "Görüşler",
            blogDescription: "Görüşler ve Yenilikler: Araştırma Blogumuz",
            moreBlogs: "Daha Fazla Blog",
            faq: "SSS",
            faqDescription: "Sorularınıza Cevaplar",
            unlockPotential: "Potansiyelinizi Özelleştirilebilir Çözümlerimizle Ortaya Çıkarın",
            dedicationText: "Kim olduğunuzu biliyoruz. Asistan doktorlar, klinisyenler veya cerrahlar olarak, hastalarınıza ve akademik kariyerinizi ilerletmeye kendinizi adadınız. İşte bu noktada biz devreye giriyoruz.",
            balancingText: "Çalışma ve akademik sorumlulukları dengelemenin zor olabileceğini anlıyoruz. Bu yüzden, hedeflerinize ulaşmanıza yardımcı olacak esnek çözümler sunuyoruz, böylece değerli zamanınızı feda etmeden başarılı olabilirsiniz. Biz, akademik hedeflerinize ulaşmanızda size yardımcı olurken siz de hastalarınıza, kariyerinize ve diğer önceliklerinize odaklanabilirsiniz.",

            // Services
            workPlanTitle: "Çalışma Planı, İş Listesi",
            workPlanDescription: "Bilimsel araştırma projeniz için sorunsuz ve verimli bir çalışma planı oluşturun. Başlamak için online yüz yüze bir görüşme planlayın.",
            graphicsTablesTitle: "Grafikler ve Tablolar",
            graphicsTablesDescription: "Araştırma sonuçlarınızı kolay anlaşılır tablolar ve içerik açısından zengin grafiklerle basitleştirin ve netleştirin. Yazılımımız, makalenizin okunabilirliğini ve sunumunu geliştirir, bulgularınızı net ve ilgi çekici hale getirir.",
            statisticalAnalysisTitle: "İstatistiksel Analiz",
            statisticalAnalysisDescription: "Veritabanınız hazır mı ve analiz planınız belirli mi? İstatistiksel analiz hizmetimiz için kısa bir form doldurarak veya uzmanımızla online konuşarak fiyat teklifi alın. Veritabanlarınızı yükleyin ve 24 saat içinde yanıt verelim.",

            // Testimonials
            testimonial1: "Araştırmam için istatistiksel analiz desteği aldığım MediMetricMinds ekibi, dergi hakemleri tarafından yapılan istatistiksel analiz eleştirilerine dayanarak çok hızlı ve etkili bir düzenleme ve yanıt mektubu yazdı. Sonunda makalem kabul edildi ve yayınlandı. Desteklerinden dolayı MediMetricMinds'a teşekkür ederim.",
            testimonial2: "Klinik araştırmalarda pek fazla deneyimim yoktu. MediMetricMinds uzman ekibiyle tek bir yüz yüze online görüşmede, yapmam gereken tüm araştırma adımlarını belirledik. Araştırma süreci uzun ve zahmetli, ancak iyi bir çalışma planı ile süreç daha sorunsuz ilerledi. Medimetricminds ekibine teşekkür ederim.",
            testimonial3: "Çalışmamın grafik görüntülerini çok kısa sürede, basit ve anlaşılır bir şekilde teslim ettiler. Teşekkür ederim...",

            // FAQ
            faqQuestion1: "Süreç Nasıl İşler?",
            faqAnswer1: "İlk aşamada, uzmanlarımızla ortak bir platformda (Zoom, Google Meet, vb.) yüz yüze bir görüşme düzenleriz. Bu ilk görüşme sırasında uzmanlarımız, ihtiyaçlarınızı ve gereksinimlerinizi belirler ve birlikte bir yol haritası oluştururuz. Görüşmeden sonra, yapılacak işler, fiyatlandırma ve sizin ve uzmanlarımızın üzerinde anlaştığı plan hakkında size e-posta yoluyla bilgi verilecektir. İş listesini onayladığınızda süreç başlar.",
            faqQuestion2: "Tıbbi makalemi yazmak istiyorum. Bana nasıl yardımcı olabilirsiniz?",
            faqAnswer2: "Uzmanlarımız, tıbbi makalenizi yazma sürecinin tamamında size rehberlik edebilir, fikirlerinizi yapılandırmaktan son taslağı parlatmaya kadar.",
            faqQuestion3: "Akademik kariyerim için makaleler üretmem gerekiyor, ama nereden başlayacağımı bilmiyorum. Bana nasıl yardımcı olabilirsiniz?",
            faqAnswer3: "Akademik makaleler üretmek için konu seçimi, araştırma tasarımı, veri analizi ve makale yazımı dahil olmak üzere kapsamlı destek sağlıyoruz.",
            faqQuestion4: "Aldığım hizmetlerle makalemin iyi bir dergide yayınlanacağını garanti edebilir misiniz?",
            faqAnswer4: "Yayınlanmayı garanti edemesek de, uzmanlarımız makalenizin saygın dergilerde kabul edilme şansını önemli ölçüde artırmak için yüksek kaliteli hizmetler sunar.",
            faqQuestion5: "Sizden istatistik hizmeti aldım ve makalem dergiden eleştiri aldı. Bana nasıl yardımcı olabilirsiniz?",
            faqAnswer5: "Uzmanlarımız, istatistiksel analizlerinizi revize ederek, gerekli olması halinde ek analizler sağlayarak ve hakem yorumlarına nasıl yanıt vereceğiniz konusunda size rehberlik ederek eleştirileri ele almanıza yardımcı olabilir.",
            faqQuestion6: "Bilimsel araştırmam için sizden istatistik hizmeti almak istiyorum, ancak verilerimi nasıl toplayacağımı bilmiyorum. Ne yapabilirim?",
            faqAnswer6: "Veri toplama sürecinizi tasarlamanızda size yardımcı olabiliriz, veri toplama araçları oluşturmak ve yüksek kaliteli veri toplamanın en iyi uygulamaları konusunda rehberlik sağlamak dahil.",
            faqQuestion7: "Sizden istatistik danışmanlığı aldım ama sonuçları makalem için nasıl formatlayacağımı bilmiyorum. Bana nasıl yardımcı olabilirsiniz?",
            faqAnswer7: "Uzmanlarımız, sonuçlarınızı hedeflediğiniz derginin yönergelerine uygun şekilde formatlamanıza ve bunların net ve etkili bir şekilde sunulmasını sağlamanıza yardımcı olabilir.",
            faqQuestion8: "Görsel Özet nedir?",
            faqAnswer8: "Görsel bir özet, araştırmanızın ana bulgularının grafiksel bir özetidir. Okuyucuların makalenizin ana noktalarını hızla anlamalarına yardımcı olur.",
            faqQuestion9: "Dergi, makalem için bir 'Ön Mektup' istedi. 'Ön Mektup' nedir?",
            faqAnswer9: "Bir ön mektup, makalenizle birlikte gönderdiğiniz bir belgedir. Makalenizi dergi editörüne tanıtır ve çalışmanızın neden önemli olduğunu ve dergiye uygun olduğunu açıklar.",
            faqQuestion10: "Başlık Sayfası nedir?",
            faqAnswer10: "Bir başlık sayfası, makalenizin ilk sayfasıdır. Makalenizin başlığını, yazarların isimlerini ve bağlı oldukları kurumları ve varsa teşekkür veya uyarıları içerir.",
            faqQuestion11: "Hizmetlerinizin fiyatları nasıl belirleniyor?",
            faqAnswer11: "Hizmetlerimizin fiyatları, projenizin kapsamına ve ihtiyaçlarınıza göre belirlenen tekliflerle sunulur. Size özel bir teklif hazırlandıktan sonra fiyatlandırma konusunda net bilgiler sağlanır.",
            faqQuestion12: "Ödemeleri nasıl yapabilirim?",
            faqAnswer12: "Ödemelerinizi, teklif sonrasında belirtilen hesap numaralarına banka havalesi veya EFT yoluyla gerçekleştirebilirsiniz. Ödeme işleminizin ardından fatura düzenlenir ve size iletilir.",

            // Footer
            menu: "Menü",
            utilities: "Araçlar",
            termsPolicies: "Şartlar ve Politikalar",
            privacyPolicy: "Gizlilik Politikası",
            getInTouch: "Bizimle İletişime Geçin:",
            contactUsToday: "Bugün Bize Ulaşın!",
            contactUs: "İletişim",
            footerNote: "© 2024 MediMetricMinds. Tüm hakları saklıdır.",

            //ABOUT US 
            "discoverOurStory": "Hikayemizi Keşfedin",
            "MediMetricMinds operates to support academic research and clinical trials. Our mission is to provide quality, flexible, and innovative solutions to professionals in the academic and clinical fields to facilitate their research processes and optimize their outcomes.": "MediMetricMinds, akademik araştırmaları ve klinik denemeleri desteklemek için faaliyet göstermektedir. Misyonumuz, akademik ve klinik alanlardaki profesyonellere araştırma süreçlerini kolaylaştırmak ve sonuçlarını optimize etmek için kaliteli, esnek ve yenilikçi çözümler sunmaktır.",
            "Consultant": "Danışman",
            "Projects": "Projeler",
            "Published in international SCI and SSCI journals": "Uluslararası SCI ve SSCI dergilerinde yayınlanan",
            "Statistical Revisions": "İstatistiksel Revizyonlar",
            "Published in local journals": "Yerel dergilerde yayınlanan",
            "Smiling Faces 😊": "Gülen Yüzler 😊",
            "Mean Data Return": "Ortalama Veri Dönüşü",
            "Days": "Gün",
            "Values": "Değerler",
            "Customer Satisfaction": "Müşteri Memnuniyeti",
            "We strive to understand our customers' needs and meet their expectations.": "Müşterilerimizin ihtiyaçlarını anlamak ve beklentilerini karşılamak için çaba gösteriyoruz.",
            "Quality": "Kalite",
            "Adding value to our customers by providing services at the highest standards.": "Hizmetlerimizi en yüksek standartlarda sunarak müşterilerimize değer katıyoruz.",
            "Flexibility": "Esneklik",
            "Offering quick and flexible solutions to meet the changing needs of our customers.": "Müşterilerimizin değişen ihtiyaçlarına hızlı ve esnek çözümler sunuyoruz.",
            "Innovation": "Yenilikçilik",
            "Supporting our customers' success by continuously developing innovative solutions.": "Yenilikçi çözümler geliştirerek müşterilerimizin başarısını destekliyoruz.",
            "What We Do?": "Ne Yapıyoruz?",
            "MediMetricMinds offers a variety of services to support academic research and clinical studies:": "MediMetricMinds, akademik araştırmaları ve klinik çalışmaları desteklemek için çeşitli hizmetler sunmaktadır:",
            "Online Consulting": "Online Danışmanlık",
            "We provide expert consulting services for clients who need guidance or are starting their research process.": "Rehberliğe ihtiyacı olan veya araştırma sürecine yeni başlayan müşterilerimize uzman danışmanlık hizmetleri sunuyoruz.",
            "Statistical Analysis and Writing Services": "İstatistiksel Analiz ve Yazım Hizmetleri",
            "We offer expert support from data analysis to writing results.": "Veri analizinden sonuçların yazılmasına kadar uzman desteği sunuyoruz.",
            "Graphic and Table Creation": "Grafik ve Tablo Oluşturma",
            "We help present research findings more effectively by visualizing them through graphics and tables.": "Araştırma bulgularını grafikler ve tablolar aracılığıyla görselleştirerek daha etkili sunulmasına yardımcı oluyoruz.",
            "Plagiarism Check": "İntihal Kontrolü",
            "We provide professional plagiarism checking services to ensure the originality of articles.": "Makalelerin özgünlüğünü sağlamak için profesyonel intihal kontrol hizmetleri sunuyoruz.",
            "Reference Management": "Referans Yönetimi",
            "We organize and update article references using EndNote.": "Makalelerin referanslarını EndNote kullanarak düzenliyor ve güncelliyoruz.",
            "Medimetricminds works with an expert team to provide the best service to our clients. Our diverse specialists, including statisticians, clinical experts, and writers, come together to offer customized solutions tailored to our clients' needs.": "Medimetricminds, müşterilerimize en iyi hizmeti sunmak için uzman bir ekiple çalışmaktadır. İstatistikçiler, klinik uzmanlar ve yazarlar gibi çeşitli uzmanlarımız, müşterilerimizin ihtiyaçlarına göre özelleştirilmiş çözümler sunmak için bir araya gelmektedir.",
            "By working with us, you can take the right steps to achieve your academic goals and use your time most efficiently. Feel free to contact us; we would be delighted to discuss how we can assist you.": "Bizimle çalışarak akademik hedeflerinize ulaşmak için doğru adımları atabilir ve zamanınızı en verimli şekilde kullanabilirsiniz. Bize ulaşmaktan çekinmeyin; size nasıl yardımcı olabileceğimizi konuşmaktan memnuniyet duyarız.",
            "VISION & MISSION": "VİZYON & MİSYON",
            "Empowering Academic Excellence": "Akademik Mükemmelliği Güçlendirme",
            "To assist our clients in achieving their academic goals, we offer statistical analysis, data management, and writing services, enabling them to utilize their time and resources most efficiently.": "Müşterilerimizin akademik hedeflerine ulaşmalarına yardımcı olmak için istatistiksel analiz, veri yönetimi ve yazım hizmetleri sunuyoruz, böylece zamanlarını ve kaynaklarını en verimli şekilde kullanmalarını sağlıyoruz.",
            "By providing the best services in the industry and contributing to scientific innovations in the academic world, we aim to be pioneers and earn the trust of our customers.": "Sektördeki en iyi hizmetleri sunarak ve akademik dünyadaki bilimsel yeniliklere katkıda bulunarak öncü olmayı ve müşterilerimizin güvenini kazanmayı hedefliyoruz.",
            "TEAM": "EKİP",
            "Meet the Experts": "Uzmanlarımızla Tanışın",
            "Lead Consultant": "Baş Danışman",
            "Congenital Cardiac Surgeon": "Konjenital Kalp Cerrahı",
            "General Manager": "Genel Müdür",
            "Operational Manager": "Operasyon Müdürü",
            "Organizational Team Leader": "Organizasyon Takım Lideri",
            "OUR POLICIES": "POLİTİKALARIMIZ",
            "Ensuring Excellence": "Mükemmelliği Sağlamak",
            "To ensure customer satisfaction and establish a reliable collaborative environment, MediMetricMinds adopts strict privacy policies and business ethics principles. By maintaining the highest quality standards, we continually review and improve our services.": "Müşteri memnuniyetini sağlamak ve güvenilir bir iş birliği ortamı oluşturmak için MediMetricMinds, sıkı gizlilik politikaları ve iş etiği ilkelerini benimser. En yüksek kalite standartlarını koruyarak hizmetlerimizi sürekli gözden geçirir ve geliştiririz.",
            "With a flexible refund and cancellation policy, we aim to maximize customer satisfaction. Through strong collaboration and effective communication with our clients, we aim to best meet their needs. These policies reflect the core values of the MediMetricMinds team and our commitment to providing reliable and professional service to our customers.": "Esnek iade ve iptal politikası ile müşteri memnuniyetini en üst düzeye çıkarmayı amaçlıyoruz. Müşterilerimizle güçlü iş birliği ve etkili iletişim yoluyla ihtiyaçlarını en iyi şekilde karşılamayı hedefliyoruz. Bu politikalar, MediMetricMinds ekibinin temel değerlerini ve müşterilerimize güvenilir ve profesyonel hizmet sunma taahhüdümüzü yansıtmaktadır.",

            //SERVICES PAGE 
            "How We Can Help You": "Size Nasıl Yardımcı Olabiliriz",
            "Let's Begin": "Hadi Başlayalım",
            "Work Plan, Work List": "Çalışma Planı, İş Listesi",
            "We can help you create a work plan and work list for your scientific research project, which is often neglected but ensures that the work items flow smoothly throughout the study. Make your appointment and simply create your work plan with our expert guidance via an online face-to-face discussion.": "Bilimsel araştırma projeniz için genellikle ihmal edilen ancak çalışma maddelerinin araştırma sürecinde sorunsuz bir şekilde ilerlemesini sağlayan bir çalışma planı ve iş listesi oluşturmanıza yardımcı olabiliriz. Randevunuzu oluşturun ve uzman rehberliğimizle çevrim içi yüz yüze bir görüşme ile iş planınızı kolayca oluşturun.",
            "Statistical Analysis": "İstatistiksel Analiz",
            "Did you say that your database is ready and the statistical analysis to be applied has been determined? Congratulations! You can get a price and working time quote for statistical analysis services. To do this, we will ask you to fill out a form that we have prepared for you to briefly inform our expert about the purpose of your research along with other relevant information. If you do not want to fill out the form, you can inform our expert about your research through an online conversation. Once you upload your database to the system along with the form, we will get back to you within 24 hours at the latest.": "Veritabanınızın hazır olduğunu ve uygulanacak istatistiksel analizlerin belirlendiğini mi söylediniz? Tebrikler! İstatistiksel analiz hizmetleri için fiyat ve çalışma süresi teklifi alabilirsiniz. Bunu yapmak için, araştırmanızın amacını ve diğer ilgili bilgileri uzmanımıza kısaca bildirmek için hazırladığımız formu doldurmanızı rica edeceğiz. Formu doldurmak istemezseniz, çevrim içi bir görüşme ile uzmanımıza araştırmanız hakkında bilgi verebilirsiniz. Veritabanınızı form ile birlikte sisteme yükledikten sonra, en geç 24 saat içinde size geri dönüş yapacağız.",
            "Graphics and Tables": "Grafikler ve Tablolar",
            "The presentation of the results of any research is in need to be simplified, summarized, and be much more easy to be understood with tables and graphics. This work increases the readability and understandability of your article with our easy-to-understand and simplified tables. With our infrastructure software, we create content-rich graphics that increase the clarity and presentability of your article.": "Herhangi bir araştırmanın sonuçlarının sunumu, grafikler ve tablolar ile sadeleştirilmeye, özetlenmeye ve anlaşılması çok daha kolay hale getirilmesi gerekmektedir. Bu çalışma, kolay anlaşılır ve sadeleştirilmiş tablolarımız ile makalenizin okunabilirliğini ve anlaşılabilirliğini artırır. Altyapı yazılımımız ile makalenizin netliğini ve sunulabilirliğini artıran zengin içerikli grafikler oluşturuyoruz.",
            "Visual/Graphical Abstract": "Görsel/Şematik Öz",
            "Many international and respected medical journals now demand the creation of a visual/graphical abstract that summarizes the method and content of scientific research as well as important findings that contribute to the literature and ensures that the new understanding is shared with the reader at first glance. You can increase the interest of your article using a content-rich visual/graphical abstract that we can create for you.": "Birçok uluslararası ve saygın tıp dergisi, bilimsel araştırmanın yöntemini, içeriğini ve literatüre katkıda bulunan önemli bulguları özetleyen ve yeni anlayışın okuyucuya ilk bakışta paylaşılmasını sağlayan görsel/şematik bir öz oluşturulmasını talep etmektedir. Sizin için oluşturabileceğimiz zengin içerikli görsel/şematik bir özet kullanarak makalenizin ilgisini artırabilirsiniz.",
            "Statistical Analysis and Results Section": "İstatistiksel Analiz ve Sonuçlar Bölümü",
            "Upon your request, we can write the 'results' section of your article and the 'statistical analysis' section of the materials and methods in line with your instructions, in mutual communication. The expert who will write the article will not only be a native English speaker but also an expert who has particular knowledge of the research topic and terminological jargon.": "Talebiniz üzerine, makalenizin 'sonuçlar' bölümünü ve 'istatistiksel analiz' bölümünü, karşılıklı iletişimde sizin talimatlarınız doğrultusunda yazabiliriz. Makaleyi yazacak olan uzman, sadece ana dili İngilizce olan biri değil, aynı zamanda araştırma konusu ve terminolojik jargon hakkında özel bilgiye sahip bir uzmandır.",
            "Citation/References": "Alıntılar/Referanslar",
            "During the article writing process, keeping minute-to-minute updated references in the text and in the references section simultaneously is not only a time-consuming but also a tiring process. At this stage, we want you to deal with the details of the content of your references and to decide which references to use and which not to use. Let us take care of the references for you using the EndNote.": "Makale yazma sürecinde, metin içinde ve referanslar bölümünde anlık olarak güncellenen referansları tutmak, zaman alıcı olmasının yanı sıra yorucu bir süreçtir. Bu aşamada, referanslarınızın içeriğinin detayları ile ilgilenmenizi ve hangi referansları kullanıp hangilerini kullanmayacağınıza karar vermenizi istiyoruz. Referanslarınızı EndNote kullanarak sizin için biz halledelim.",
            "Pre-Submission Peer-Review": "Gönderim Öncesi Hakem İncelemesi",
            "Do you think the final version of your article is okay? Critical peer review before submitting your article to the journal is called pre-submission peer review and helps you pass the evaluations of the article with less damage by the referees determined by the journal. Our experts who support this issue are familiar with the perspective of journal referees.": "Makalemizin son halinin tamam olduğunu mu düşünüyorsunuz? Makalenizi dergiye göndermeden önce yapılan kritik hakem değerlendirmesi, gönderim öncesi hakem incelemesi olarak adlandırılır ve makalenizin derginin belirlediği hakemler tarafından yapılan değerlendirmeleri daha az zararla geçmenize yardımcı olur. Bu konuda destek veren uzmanlarımız, dergi hakemlerinin bakış açısına aşinadır.",
            "Edit/Submit": "Düzenleme/Gönderme",
            "We feel and know that you are in a very busy work tempo. We can edit your completed article according to the submission guideline of the target journal you want to submit and submit it to the journal on your behalf. Just simply make a request from us.": "Çok yoğun bir çalışma temposunda olduğunuzu hissediyor ve biliyoruz. Tamamlanmış makalenizi, göndermek istediğiniz hedef derginin gönderim kılavuzuna göre düzenleyebilir ve makalenizi sizin adınıza dergiye gönderebiliriz. Sadece bizden bir talepte bulunmanız yeterlidir.",
            "Title Page / Cover Letter": "Başlık Sayfası / Kapak Mektubu",
            "Each article requires a title page and cover letter when being sent to a journal. The title page is requested from the authors in order to ensure that the referees evaluate your article in a healthy and impartial manner. It is aimed to hide in which center and by whom the research was conducted throughout your article text. The 'cover letter' is requested to briefly inform the journal editor that your research findings are not in the process of being evaluated simultaneously by another journal and the important points that your research findings contribute to science. These services are provided on your behalf by our experts.": "Her makale, bir dergiye gönderilirken bir başlık sayfası ve kapak mektubu gerektirir. Başlık sayfası, hakemlerin makalenizi sağlıklı ve tarafsız bir şekilde değerlendirmelerini sağlamak amacıyla yazarlardan istenir. Araştırmanın hangi merkezde ve kim tarafından yapıldığı makale metninizde gizlenmesi hedeflenir. 'Kapak mektubu', araştırma bulgularınızın başka bir dergi tarafından eş zamanlı olarak değerlendirilmekte olmadığı ve bulgularınızın bilime katkıda bulunduğu önemli noktalar hakkında dergi editörünü kısaca bilgilendirmek için talep edilir. Bu hizmetler uzmanlarımız tarafından sizin adınıza sağlanır.",
            "Plagiarism Check": "İntihal Kontrolü",
            "Today, many journals and publishers use plagiarism detection software to prevent plagiarism. The similarity index obtained as a result of this check is used as a criterion, and many publishers require the similarity index of the articles they wish to publish to be below 20%. We want to let you know that we are here to support you in this matter. We perform plagiarism checks with multiple licensed software and provide the results to you as a report.": "Günümüzde birçok dergi ve yayınevi, intihali önlemek için intihal tespit yazılımlarını kullanmaktadır. Bu kontrol sonucunda elde edilen benzerlik indeksi bir kriter olarak kullanılır ve birçok yayınevi, yayımlamak istedikleri makalelerin benzerlik indeksinin %20'nin altında olmasını talep eder. Bu konuda sizi desteklemek için burada olduğumuzu belirtmek isteriz. Çok sayıda lisanslı yazılım ile intihal kontrolü yapıyor ve sonuçları size rapor olarak sunuyoruz.",
            "Similarity Index Reduction": "Benzerlik İndeksi Azaltma",
            "Did your article's similarity index exceed 20% as a result of the plagiarism check? If so, how about using our SIR service to reduce your article's similarity index below 20%?": "Makalemizin benzerlik indeksi, intihal kontrolü sonucunda %20'yi aştı mı? Eğer öyleyse, makalenizin benzerlik indeksini %20'nin altına düşürmek için SIR hizmetimizi kullanmaya ne dersiniz?",
            "Translation": "Çeviri",
            "Writing your article in your native language other than English means expressing what you want to convey more effectively. We would like to support you in this matter. You write your article in your own language, and we will translate it into English with expert native translators.": "Makalemizi İngilizce dışındaki ana dilinizde yazmak, iletmek istediğiniz şeyi daha etkili bir şekilde ifade etmek anlamına gelir. Bu konuda sizi desteklemek istiyoruz. Makalenizi kendi dilinizde yazın, biz de uzman ana dilde tercümanlarımızla İngilizceye çevirelim.",
            "Proofreading": "Düzeltme",
            "Did you write your article in English and want to undergo a comprehensive anatomical and expressionist review? We can support you with our proofreading service in this matter.": "Makalenizi İngilizce olarak yazdınız mı ve kapsamlı bir anatomik ve anlatımsal inceleme yapmak istiyor musunuz? Bu konuda düzeltme hizmetimizle size destek olabiliriz.",
            "English Support & IELTS Courses": "İngilizce Desteği & IELTS Kursları",
            "As much as the English used in daily life, the English language skills you will use in your academic life are also very important. Every language course has its unique focus areas. Preparing for English proficiency exams with our team will have a positive impact on your academic life. Please contact us for specially prepared free introductory seminars.": "Günlük hayatta kullanılan İngilizce kadar, akademik hayatınızda kullanacağınız İngilizce dil becerileri de çok önemlidir. Her dil kursunun kendine özgü odak alanları vardır. Ekibimizle İngilizce yeterlilik sınavlarına hazırlık yapmak, akademik hayatınız üzerinde olumlu bir etki yaratacaktır. Özel olarak hazırlanan ücretsiz tanıtım seminerlerimiz için lütfen bizimle iletişime geçin.",

            //BLOG PAGE

            "What is Statistics and Biostatistics?": "İstatistik ve Biyoistatistik Nedir?",
            "What is Statistics?": "İstatistik Nedir?",
            "Statistics is a discipline that encompasses the processes of data collection, analysis, interpretation, and reporting. For researchers, it is important to evaluate their data with the most appropriate analysis techniques and reach accurate results.": "İstatistik, verilerin toplanması, analizi, yorumlanması ve raporlanması süreçlerini kapsayan bir disiplindir. Araştırmacılar için önemli olan, verilerini en uygun analiz teknikleriyle değerlendirip doğru sonuçlara ulaşmaktır.",
            "Statistics broadly includes the measurements we use to estimate data or population parameters. However, it is generally the process of processing and making sense of the data obtained.": "İstatistik, geniş anlamda veri veya anakütle parametrelerini tahmin etmek için kullandığımız ölçümleri içerir. Ancak genel olarak, elde edilen verilerin işlenmesi ve anlamlandırılması sürecidir.",
            "What is Biostatistics?": "Biyoistatistik Nedir?",
            "Biostatistics is the application of statistical methods in biology, medicine, and health sciences. In Turkey, biostatistics often operates as a major department within medical faculties.": "Biyoistatistik ise istatistiksel yöntemlerin biyoloji, tıp ve sağlık bilimlerinde uygulanmasıdır. Türkiye’de biyoistatistik, genellikle tıp fakülteleri bünyesinde ana bilim dalı olarak faaliyet gösterir.",
            "This close relationship can sometimes lead to the perception that biostatistics is a completely different field from statistics.": "Bu yakın ilişki, bazen biyoistatistiğin istatistikten tamamen farklı bir alanmış gibi algılanmasına sebep olabilir.",
            "Differences and Common Points Between Statistics and Biostatistics": "İstatistik ve Biyoistatistik Arasındaki Farklar ve Ortak Noktalar",
            "The difference between statistics and biostatistics arises from the application area of the analyses. Statistics includes general data analysis techniques, while biostatistics covers specialized analyses in fields like medicine and biology.": "İstatistik ve biyoistatistik arasındaki fark, analizlerin uygulama alanından kaynaklanır. İstatistik, genel veri analiz tekniklerini içerirken, biyoistatistik tıp ve biyoloji gibi alanlarda özelleşmiş analizleri kapsar.",
            "Basic statistical techniques (such as t-test, ANOVA, correlation analysis) are applied similarly in both fields.": "Temel istatistiksel teknikler (t-testi, ANOVA, korelasyon analizi gibi) her iki alanda da aynı şekilde uygulanır.",
            "Biostatistical Analysis: Not a Simple Step": "Biyoistatistik Analizi: Basit Bir Basamak Değil",
            "Biostatistical analysis is one of the most critical stages of scientific research and directly affects its reliability.": "Biyoistatistik analizi, bilimsel araştırmanın en kritik aşamalarından biridir ve güvenilirliği doğrudan etkiler.",
            "Using the correct statistical methods ensures the reliability of the results obtained.": "Doğru istatistiksel yöntemlerin kullanılması, elde edilen sonuçların güvenilirliğini sağlamaktadır.",
            "What is SPSS?": "SPSS Nedir?",
            "SPSS and Its Use: Importance and Scope in Biostatistics": "SPSS ve Kullanımı: Biyoistatistikteki Önemi ve Kapsamı",
            "SPSS is a statistical analysis software developed for social sciences. However, over time, it has become widely used in various scientific fields, including biostatistics, due to its broad data analysis capabilities.": "SPSS, sosyal bilimler için geliştirilmiş bir istatistiksel analiz yazılımıdır. Ancak zamanla, geniş veri analiz kabiliyetleri sayesinde biyoistatistik de dahil olmak üzere çeşitli bilimsel alanlarda yaygın olarak kullanılmaya başlanmıştır.",
            "SPSS allows researchers to easily analyze complex data sets with its user-friendly interface and strong analytical capabilities.": "SPSS, kullanıcı dostu arayüzü ve güçlü analiz yetenekleri ile araştırmacıların karmaşık veri setlerini kolayca analiz etmelerine olanak tanır.",
            "The Use of SPSS in Biostatistics": "SPSS'in Biyoistatistikte Kullanımı",
            "Biostatistics is a discipline that requires data analysis in the fields of health and biology. SPSS offers a powerful set of tools to perform these analyses.": "Biyoistatistik, sağlık ve biyoloji alanlarında veri analizi yapmayı gerektiren bir disiplindir. SPSS, bu analizleri gerçekleştirmek için güçlü bir araç seti sunar.",
            "Researchers can streamline data set management and quickly handle missing data using SPSS.": "Araştırmacılar, SPSS kullanarak veri setlerinin yönetimini kolaylaştırır ve eksik verileri hızlıca işleyebilir.",
            "Plagiarism": "İntihal (Plagiarism)",
            "What is Plagiarism?": "İntihal Nedir?",
            "Plagiarism means presenting someone else's work or ideas as your own.": "İntihal, başka birinin çalışmasını veya fikirlerini kendinizinmiş gibi sunmak anlamına gelir.",
            "If you see two contents that are highly similar or exactly the same in different places, it is highly likely that one of them is plagiarized.": "Farklı yerlerde ileri derecede benzer veya tıpa tıp aynı iki içerik görürseniz, bunlardan birinin intihal olması yüksek ihtimaldir.",
            "How to Avoid Plagiarism?": "İntihalden Nasıl Kaçınılır?",
            "To avoid plagiarism, you need to rewrite the content using different words. The aim of your rewriting process should be to present the idea in your own words while still attributing the original author.": "İntihalden kaçınmak için, içeriği başka kelimeler kullanarak yeniden yazmanız gerekmektedir. Yeniden yazma sürecinizin amacı, fikri kendi kelimelerinizle sunmak ancak orijinal yazara atıfta bulunmak olmalıdır.",
            "You can use plagiarism checkers and interpretation tools to avoid plagiarism.": "İntihalden kaçınmak için intihal denetleyicileri ve yorumlama araçları kullanabilirsiniz.",
            "What Do We Offer As Medimetricminds?": "Medimetricminds Olarak Neler Sunuyoruz?",
            "Consultancy Services of Medimetricminds": "Medimetricminds'ın Danışmanlık Hizmetleri",
            "As Medimetricminds, we offer expert consultancy services in the fields of statistics and biostatistics.": "Medimetricminds olarak, istatistik ve biyoistatistik alanlarında uzman danışmanlık hizmetleri sunuyoruz.",
            "We provide support across a wide range, from statistical hypothesis tests to complex modeling.": "İstatistiksel hipotez testlerinden karmaşık modellemelere kadar geniş bir yelpazede destek sağlıyoruz.",
            "Our SPSS Consultancy Services As Medimetricminds": "Medimetricminds Olarak SPSS Danışmanlık Hizmetlerimiz",
            "Our SPSS Consultancy Services": "SPSS Danışmanlık Hizmetlerimiz",
            "We analyze your data sets using SPSS and prepare reports in accordance with scientific standards.": "SPSS kullanarak veri setlerinizi analiz ediyor ve bilimsel standartlara uygun raporlar hazırlıyoruz.",
            "We organize training on SPSS and biostatistics and ensure that researchers can use these tools effectively.": "SPSS ve biyoistatistik konularında eğitimler düzenliyor ve araştırmacıların bu araçları etkin bir şekilde kullanmalarını sağlıyoruz.",
            "Why Medimetricminds?": "Neden Medimetricminds?",
            "Expert Staff and Comprehensive Services": "Uzman Kadro ve Kapsamlı Hizmetler",
            "We provide the most accurate analyses with our expert team who have academic and professional experience.": "Akademik ve profesyonel deneyime sahip uzman ekibimizle en doğru analizleri sunuyoruz.",
            "We provide comprehensive consultancy in data collection, analysis, interpretation, and reporting processes.": "Veri toplama, analiz, yorumlama ve raporlama süreçlerinde kapsamlı danışmanlık sağlıyoruz.",
            "Online Consultancy Services with Medimetricminds": "Medimetricminds ile Online Danışmanlık Hizmetleri",
            "Advantages of Online Consultancy Services": "Online Danışmanlık Hizmetlerinin Avantajları",
            "With easy access and quick solutions, you can reach our expert consultants from anywhere with an internet connection.": "Kolay erişim, hızlı çözüm ile internet bağlantınızın olduğu her yerden uzman danışmanlarımıza ulaşabilirsiniz.",
            "You can focus on your academic research and save time without the need for physical meetings.": "Fiziksel görüşmelere gerek kalmadan akademik araştırmalarınıza odaklanabilir ve zamandan tasarruf edebilirsiniz.",
            "Need a Private Lesson?": "Özel Ders mi İhtiyacınız Var?",
            "If you require personalized guidance to achieve your academic or research goals, we offer private lessons tailored to your needs. Our experts are ready to provide one-on-one support to help you succeed. Contact us today to schedule your private session and get started on the path to excellence.": "Akademik veya araştırma hedeflerinize ulaşmak için kişiselleştirilmiş rehberlik ihtiyacınız varsa, ihtiyaçlarınıza uygun özel dersler sunuyoruz. Uzmanlarımız, başarılı olmanıza yardımcı olmak için bire bir destek sağlamaya hazırdır. Özel oturumunuzu planlamak ve mükemmeliyet yolunda adım atmak için bugün bize ulaşın.",
            "Inquire Now": "Hemen Bilgi Alın",
            "Blog": "Blog",
            "Search": "Ara",
            "Read More": "Daha Fazla Okuyun...",

            //CONTACT
            "Schedule a Consultation": "Bir Danışma Planlayın",
            "Online Face-to-Face Consultation": "Çevrimiçi Yüz Yüze Danışma",
            "If you do not know where and how to start, if you are at the very beginning of your research, you can get online face-to-face support from our experts in determining the design and variables of the study and creating your database. Apart from these, our experts will be with you for any questions you may have or issues that need to be resolved. To do this, you can make an appointment for an online consultation with our experts at a time that suits your calendar.": "Nereden ve nasıl başlayacağınızı bilmiyorsanız, araştırmanızın en başındaysanız, çalışmanın tasarımı ve değişkenlerinin belirlenmesi ve veri tabanınızın oluşturulmasında uzmanlarımızdan çevrimiçi yüz yüze destek alabilirsiniz. Bunların dışında, uzmanlarımız herhangi bir sorunuz veya çözülmesi gereken bir sorun için sizinle birlikte olacaktır. Bunun için, takviminize uygun bir zamanda uzmanlarımızla çevrimiçi bir danışma için randevu alabilirsiniz.",
            "First name*": "Ad*",
            "First name": "Ad",
            "Last name*": "Soyad*",
            "Last name": "Soyad",
            "Email*": "E-posta*",
            "Email": "E-posta",
            "Phone number*": "Telefon numarası*",
            "Phone number": "Telefon numarası",
            "Add File*": "Dosya Ekle*",
            "Message*": "Mesaj*",
            "Please briefly describe the topic you would like to discuss with the expert": "Uzmanla görüşmek istediğiniz konuyu kısaca açıklayın",
            "Book an Appointment": "Randevu Al",

            //BlogDetail

            "What are Statistics and Biostatistics?": "İstatistik ve Biyoistatistik Nedir?",
            "What is Statistics?": "İstatistik Nedir?",
            "Statistics is a discipline that encompasses the processes of collecting, analyzing, interpreting, and reporting data. What is important for researchers is to evaluate their data with the most appropriate analysis techniques and to reach correct results.": "İstatistik, verilerin toplanması, analizi, yorumlanması ve raporlanması süreçlerini kapsayan bir disiplindir. Araştırmacılar için önemli olan, verilerini en uygun analiz teknikleriyle değerlendirip doğru sonuçlara ulaşmaktır.",
            "Statistics broadly includes the measurements we use to estimate data or population parameters. Generally, it is the process of processing and making sense of the data obtained.": "İstatistik, geniş anlamda veri veya anakütle parametrelerini tahmin etmek için kullandığımız ölçümleri içerir. Ancak genel olarak, elde edilen verilerin işlenmesi ve anlamlandırılması sürecidir.",
            "What is Biostatistics?": "Biyoistatistik Nedir?",
            "Biostatistics is the application of statistical methods in biology, medicine, and health sciences. In Turkey, biostatistics usually operates as a main scientific branch within medical faculties.": "Biyoistatistik ise istatistiksel yöntemlerin biyoloji, tıp ve sağlık bilimlerinde uygulanmasıdır. Türkiye’de biyoistatistik, genellikle tıp fakülteleri bünyesinde ana bilim dalı olarak faaliyet gösterir.",
            "This close relationship can sometimes lead to the perception that biostatistics is a completely different field from statistics.": "Bu yakın ilişki, bazen biyoistatistiğin istatistikten tamamen farklı bir alanmış gibi algılanmasına sebep olabilir.",
            "Differences and Commonalities Between Statistics and Biostatistics": "İstatistik ve Biyoistatistik Arasındaki Farklar ve Ortak Noktalar",
            "The difference between statistics and biostatistics arises from the area of application of the analyses. While statistics includes general data analysis techniques, biostatistics covers specialized analyses in fields such as medicine and biology.": "İstatistik ve biyoistatistik arasındaki fark, analizlerin uygulama alanından kaynaklanır. İstatistik, genel veri analiz tekniklerini içerirken, biyoistatistik tıp ve biyoloji gibi alanlarda özelleşmiş analizleri kapsar.",
            "Basic statistical techniques (such as t-test, ANOVA, correlation analysis) are applied in the same way in both fields.": "Temel istatistiksel teknikler (t-testi, ANOVA, korelasyon analizi gibi) her iki alanda da aynı şekilde uygulanır.",
            "Biostatistical Analysis: Not Just a Simple Step": "Biyoistatistik Analizi: Basit Bir Basamak Değil",
            "Biostatistical analysis is one of the most critical stages of scientific research and directly affects its reliability.": "Biyoistatistik analizi, bilimsel araştırmanın en kritik aşamalarından biridir ve güvenilirliği doğrudan etkiler.",
            "Using the correct statistical methods ensures the reliability of the results obtained.": "Doğru istatistiksel yöntemlerin kullanılması, elde edilen sonuçların güvenilirliğini sağlamaktadır.",
            "What is SPSS?": "SPSS Nedir?",
            "SPSS and Its Use: Importance and Scope in Biostatistics": "SPSS ve Kullanımı: Biyoistatistikteki Önemi ve Kapsamı",
            "SPSS is a statistical analysis software developed for social sciences. However, over time, due to its broad data analysis capabilities, it has started to be widely used in various scientific fields, including biostatistics.": "SPSS, sosyal bilimler için geliştirilmiş bir istatistiksel analiz yazılımıdır. Ancak zamanla, geniş veri analiz kabiliyetleri sayesinde biyoistatistik de dahil olmak üzere çeşitli bilimsel alanlarda yaygın olarak kullanılmaya başlanmıştır.",
            "SPSS allows researchers to easily analyze complex data sets with its user-friendly interface and powerful analysis capabilities.": "SPSS, kullanıcı dostu arayüzü ve güçlü analiz yetenekleri ile araştırmacıların karmaşık veri setlerini kolayca analiz etmelerine olanak tanır.",
            "Use of SPSS in Biostatistics": "SPSS'in Biyoistatistikte Kullanımı",
            "Biostatistics is a discipline that requires data analysis in health and biology fields. SPSS provides a powerful toolset to perform these analyses.": "Biyoistatistik, sağlık ve biyoloji alanlarında veri analizi yapmayı gerektiren bir disiplindir. SPSS, bu analizleri gerçekleştirmek için güçlü bir araç seti sunar.",
            "Researchers can use SPSS to facilitate the management of their data sets and quickly process missing data.": "Araştırmacılar, SPSS kullanarak veri setlerinin yönetimini kolaylaştırır ve eksik verileri hızlıca işleyebilir.",
            "Plagiarism": "İntihal",
            "What is Plagiarism?": "İntihal Nedir?",
            "Plagiarism means presenting someone else’s work or ideas as your own.": "İntihal, başka birinin çalışmasını veya fikirlerini kendinizinmiş gibi sunmak anlamına gelir.",
            "If you see two contents that are highly similar or identical in different places, it is highly likely that one of them is plagiarism.": "Farklı yerlerde ileri derecede benzer veya tıpa tıp aynı iki içerik görürseniz, bunlardan birinin intihal olması yüksek ihtimaldir.",
            "How to Avoid Plagiarism?": "İntihalden Nasıl Kaçınılır?",
            "To avoid plagiarism, you need to rewrite the content using different words. The purpose of your rewriting process should be to present the idea in your own words but still refer to the original author.": "İntihalden kaçınmak için, içeriği başka kelimeler kullanarak yeniden yazmanız gerekmektedir. Yeniden yazma sürecinizin amacı, fikri kendi kelimelerinizle sunmak ancak orijinal yazara atıfta bulunmak olmalıdır.",
            "You can use plagiarism checkers and interpretation tools to avoid plagiarism.": "İntihalden kaçınmak için intihal denetleyicileri ve yorumlama araçları kullanabilirsiniz.",
            "What Does Medimetricminds Offer?": "Medimetricminds Olarak Neler Sunuyoruz?",
            "Consulting Services of Medimetricminds": "Medimetricminds'ın Danışmanlık Hizmetleri",
            "At Medimetricminds, we offer expert consulting services in the fields of statistics and biostatistics.": "Medimetricminds olarak, istatistik ve biyoistatistik alanlarında uzman danışmanlık hizmetleri sunuyoruz.",
            "We provide support in a wide range, from statistical hypothesis testing to complex modeling.": "İstatistiksel hipotez testlerinden karmaşık modellemelere kadar geniş bir yelpazede destek sağlıyoruz.",
            "SPSS Consulting Services at Medimetricminds": "Medimetricminds Olarak SPSS Danışmanlık Hizmetlerimiz",
            "Our SPSS Consulting Services": "SPSS Danışmanlık Hizmetlerimiz",
            "We analyze your data sets using SPSS and prepare reports in line with scientific standards.": "SPSS kullanarak veri setlerinizi analiz ediyor ve bilimsel standartlara uygun raporlar hazırlıyoruz.",
            "We organize trainings on SPSS and biostatistics, enabling researchers to use these tools effectively.": "SPSS ve biyoistatistik konularında eğitimler düzenliyor ve araştırmacıların bu araçları etkin bir şekilde kullanmalarını sağlıyoruz.",
            "Why Medimetricminds?": "Neden Medimetricminds?",
            "Expert Team and Comprehensive Services": "Uzman Kadro ve Kapsamlı Hizmetler",
            "We offer the most accurate analyses with our expert team that has academic and professional experience.": "Akademik ve profesyonel deneyime sahip uzman ekibimizle en doğru analizleri sunuyoruz.",
            "We provide comprehensive consulting in data collection, analysis, interpretation, and reporting processes.": "Veri toplama, analiz, yorumlama ve raporlama süreçlerinde kapsamlı danışmanlık sağlıyoruz.",
            "Online Consulting Services with Medimetricminds": "Medimetricminds ile Online Danışmanlık Hizmetleri",
            "Advantages of Online Consulting Services": "Online Danışmanlık Hizmetlerinin Avantajları",
            "With easy access and quick solutions, you can reach our expert consultants from anywhere with an internet connection.": "Kolay erişim, hızlı çözüm ile internet bağlantınızın olduğu her yerden uzman danışmanlarımıza ulaşabilirsiniz.",
            "You can focus on your academic research and save time without the need for physical meetings.": "Fiziksel görüşmelere gerek kalmadan akademik araştırmalarınıza odaklanabilir ve zamandan tasarruf edebilirsiniz.",
            "Our SPSS Consulting Services": "SPSS Danışmanlık Hizmetlerimiz",
            "We offer expert consulting services in SPSS and biostatistics. We analyze your data sets using SPSS and prepare reports in accordance with scientific standards.": "SPSS ve biyoistatistik alanlarında uzman danışmanlık hizmetleri sunuyoruz. SPSS kullanarak veri setlerinizi analiz ediyor ve bilimsel standartlara uygun raporlar hazırlıyoruz.",
            "Additionally, we organize trainings on SPSS and biostatistics to help researchers use these tools effectively.": "Ayrıca, SPSS ve biyoistatistik konularında eğitimler düzenleyerek araştırmacıların bu araçları etkin bir şekilde kullanmalarını sağlıyoruz.",
            "Blog post not found": "Blog gönderisi bulunamadı",
            "No content available for this blog post.": "Bu blog gönderisi için içerik mevcut değil.",

            //TESTIMONIALS 
            "Medimetricminds helped me with statistical analysis for my study. Based on the suggestions made by the journal referees based on statistical analysis, they wrote a very quick and good edit and answer letter. Finally, my paper was accepted and put out there. I'd like to thank MediMetricMinds for their help.": "Medimetricminds, çalışmam için istatistiksel analiz konusunda bana yardımcı oldu. Dergi hakemlerinin istatistiksel analiz temelinde yaptığı öneriler doğrultusunda çok hızlı ve güzel bir düzenleme ve yanıt mektubu yazdılar. Sonunda makalem kabul edildi ve yayımlandı. Yardımları için MediMetricMinds'a teşekkür ederim.",

            "I did not have much experience in clinical research. In a single face-to-face online meeting with the medimetrics expert team, we determined all the research steps I needed to do. The research process is long and laborious, but with a useful workplan, the process went more smoothly. Thanks a lot…": "Klinik araştırmalarda pek fazla deneyimim yoktu. Medimetricminds uzman ekibiyle yaptığımız tek bir yüz yüze online toplantıda yapmam gereken tüm araştırma adımlarını belirledik. Araştırma süreci uzun ve zahmetli, ancak yararlı bir çalışma planı ile süreç daha sorunsuz ilerledi. Çok teşekkürler…",

            "They quickly and easily provided me with the graphic pictures I needed for my business. I'm grateful.": "İşim için ihtiyaç duyduğum grafik resimleri hızlı ve kolay bir şekilde sağladılar. Minnettarım.",

            "Hello everyone... Emrah Şişli had previously assisted me with some of my articles. He has been very understanding and has helped and guided me in many aspects of my work with a positive interaction. I thank him and wish him success in his new field.": "Herkese merhaba... Emrah Şişli daha önceden de bazı makalelerimde bana yardımcı olmuştu. Kendisi çok anlayışlı ve olumlu bir etkileşim ile çalışmalarıma birçok konuda yardıcı oldu ve yön gösterdi. Kendisine teşekkür eder, yeni alanında kendisine başarılar dilerim.",

            "I had the peer review of my article, which I completed for Medimetricminds, done. With the critical corrections they made, my article was accepted for publication by the journal in one go. It also cost much less than similar consultants in the market. Thank you.": "Medimetricminds'a tamamlamış olduğum makalemin peer-review'ını yaptırdım. Yaptıkları kritik düzeltmeler ile makalem tek seferde dergi tarafından yayına kabul edildi. Ayrıca piyasadaki benzer danışmanlardan çok daha uygun bir fiyata mal oldu. Teşekkürler.",

            "Hello friends. The advisory team is working very cohesively, and everyone is fulfilling their responsibilities properly. My online face-to-face consultations were very productive. Apart from the support services I received, I can say that the consultants also broadened my horizons in terms of scientific research. I sincerely thank the entire team.": "Selam dostlar. Danışman ekibi çok koordineli bir şekilde çalışıyor ve herkes kendi üzerine düşeni hakkıyla yerine getiriyor. Online yüz-yüze konsültasyonlarım çok verimli geçti. Aldığım destek hizmetinin dışında, danışmanların, bilimsel araltırma konusunda da ufkumu genişlettiklerini söyleyebilirim. Tüm ekibe tüm içtenliğimle teşekkür ederim.",

            "Hello... During my residency, I was directed to Medimetrics on the recommendation of a friend for my thesis. The meetings I had with the consultants were very productive. They provided all the necessary support on time, focusing on the goal. They even increased the value of my article with their guidance at certain points. I can say that my horizons have broadened in terms of scientific research. I recommend it...": "Merhaba... Medimetrics ile ihtisasım sırasında tezim için bir arkadaşımın tavsiyesiyle yönlendirildim. Konsültanlarla yaptığım görüşmeler çok verimliydi. Hedefe odaklı olarak gerekli tüm destekleri zamanında sağladılar. Hatta bazı noktalarda yönlendirmeleri ile makalemin değerini arttırdılar. Bilimsel araştırma konusunda da ufkumun genişlediğini söyleyebilirim. Tavsiye ederim...",

            "Hello. I sought their support in preparing a visual abstract along with statistical analysis for my research on neurogenic bladder. They are very positive and very sensitive about deadlines. I recommend it, thank you.": "Merhaba. Nörojenik mesane ile ilgili araştırmamda istatistiksel analiz ile birlikte visual abstract hazırlanmasında desteklerine başvurdum. Çok olumlular ve deadline konusunda çok hassaslar. Tavsiye ederim, teşekkür.",

            "Hello. In my research on cardiac surgery, the consultant cardiac surgeon who was interested in me guided me during the online meeting on how to proceed with the article, even before I asked for it. I highly recommend that you meet with the consultants for free. Wishing everyone a smooth time...": "Selamlar. Kalp cerrahisi ile ilgili araştırmamda benimle ilgilenen konsültan kalp cerrahı idi ve online görüşmede zaten makalede nasıl bir yol izlememiz konusunda beni, ben daha talep etmeden yönlendirdi. Konsültanlarla görüşme ücretsiz ve şiddetle tavsiye ederim. Herkese kolay gelsin...",


            //BLOGLIST TRASNLATIONS


            "What Are Statistics and Biostatistics?": "İstatistik ve Biyoistatistik Nedir?",
            "Introduction": "Giriş",
            "We live in the age of data, and statistics and biostatistics, which are indispensable tools for scientific research, are crucial for researchers. At MedimetricMinds, we want to explain what these two disciplines are and how they are used.": "Veri çağında yaşıyoruz ve bilimsel araştırmaların vazgeçilmez araçları olan istatistik ve biyoistatistik, araştırmacılar için hayati önem taşıyor. MedimetricMinds olarak, bu iki disiplinin ne olduğunu ve nasıl kullanıldığını açıklamak istiyoruz.",
            "What Is Statistics?": "İstatistik Nedir?",
            "Statistics is a discipline that encompasses the processes of collecting, analyzing, interpreting, and reporting data. For researchers, it is important to evaluate their data with the most appropriate analysis techniques to achieve accurate results. Statistics broadly includes measurements used to estimate data or population parameters. It generally refers to the process of processing and interpreting the data obtained.": "İstatistik, verilerin toplanması, analizi, yorumlanması ve raporlanması süreçlerini kapsayan bir disiplindir. Araştırmacılar için önemli olan, verilerini en uygun analiz teknikleriyle değerlendirip doğru sonuçlara ulaşmaktır. İstatistik, geniş anlamda veri veya anakütle parametrelerini tahmin etmek için kullandığımız ölçümleri içerir. Ancak genel olarak, elde edilen verilerin işlenmesi ve anlamlandırılması sürecidir.",
            "What Is Biostatistics?": "Biyoistatistik Nedir?",
            "Biostatistics is the application of statistical methods to biology, medicine, and health sciences. In Turkey, biostatistics is usually conducted as a main discipline within medical faculties, creating a strong connection between other medical sciences and biostatistics. This close relationship can sometimes lead to the perception that biostatistics is a completely different field from statistics.": "Biyoistatistik, istatistiksel yöntemlerin biyoloji, tıp ve sağlık bilimlerinde uygulanmasıdır. Türkiye’de biyoistatistik, genellikle tıp fakülteleri bünyesinde ana bilim dalı olarak faaliyet gösterir ve bu da diğer tıbbi bilimler ile biyoistatistik arasındaki güçlü bağı oluşturmaktadır. Bu yakın ilişki, bazen biyoistatistiğin istatistikten tamamen farklı bir alanmış gibi algılanmasına neden olabilir.",
            "Differences and Commonalities Between Statistics and Biostatistics": "İstatistik ve Biyoistatistik Arasındaki Farklar ve Ortak Noktalar",
            "The difference between statistics and biostatistics arises from the application area of the analyses. While statistics includes general data analysis techniques, biostatistics involves specialized analyses in fields such as medicine and biology. However, fundamental statistical techniques (such as t-tests, ANOVA, correlation analysis) are applied in both fields in the same way. Therefore, a statistician has the knowledge to analyze medical data, but they need detailed guidance on the relationships between the data.": "İstatistik ve biyoistatistik arasındaki fark, analizlerin uygulama alanından kaynaklanır. İstatistik, genel veri analiz tekniklerini içerirken, biyoistatistik tıp ve biyoloji gibi alanlarda özelleşmiş analizleri kapsar. Ancak temel istatistiksel teknikler (t-testi, ANOVA, korelasyon analizi gibi) her iki alanda da aynı şekilde uygulanır. Bu nedenle, bir istatistik uzmanı tıbbi verileri analiz edebilecek bilgiye sahiptir; ancak veriler arasındaki ilişkinin ayrıntılı bir şekilde kendilerine gösterilmesi gerekmektedir.",
            "Biostatistical Analysis: Not a Simple Step": "Biyoistatistik Analizi: Basit Bir Basamak Değil",
            "Many people may view biostatistical analysis as a simple step in the scientific research process. However, this is a misconception. Biostatistical analysis is one of the most critical stages of research and directly affects the reliability and validity of scientific research. Here are some factors explaining why this process is so important:": "Birçok kişi, biyoistatistik analizini bilimsel araştırma sürecinin basit bir basamağı olarak görebilir. Ancak bu yanlış bir algıdır. Biyoistatistiksel analiz, araştırmanın en kritik aşamalarından biridir ve bilimsel araştırmanın güvenilirliğini ve geçerliliğini doğrudan etkiler. İşte bu sürecin neden bu kadar önemli olduğunu açıklayan bazı faktörler:",
            "1. Determining Scientific Value: Researchers rely on biostatistical analyses to determine the scientific value of their work. Using the correct statistical methods ensures the reliability of the results obtained.": "1. Bilimsel Değerlerin Belirlenmesi: Araştırmacılar, çalışmanın bilimsel değerini belirlemek için biyoistatistiksel analizlere güvenirler. Doğru istatistiksel yöntemlerin kullanılması, elde edilen sonuçların güvenilirliğini sağlar.",
            "2. Organizing Groups: Biostatistics presents complex data groups in a more meaningful and organized manner, which facilitates the interpretation and understanding of the results.": "2. Grupların Düzenlenmesi: Biyoistatistik, karmaşık veri gruplarını daha anlamlı ve düzenli bir şekilde sunar. Bu, sonuçların yorumlanmasını ve anlaşılmasını kolaylaştırır.",
            "3. Data Collection and Processing: How data is collected and processed directly affects the success of the analysis process. Biostatistics ensures the accuracy of data collection methods and the correct processing of data.": "3. Veri Toplama ve İşleme: Verilerin nasıl toplandığı ve işlendiği, analiz sürecinin başarısını doğrudan etkiler. Biyoistatistik, veri toplama yöntemlerinin doğruluğunu ve verilerin doğru bir şekilde işlenmesini garanti eder.",
            "4. Reporting Results: Biostatistical analyses ensure that research results are reported accurately and effectively. This helps the scientific community understand and evaluate both how the findings were obtained and the results themselves.": "4. Sonuçların Raporlanması: Biyoistatistik analizleri, araştırmanın sonuçlarının doğru ve etkili bir şekilde raporlanmasını sağlar. Bu, bilimsel topluluğun bulguların nasıl elde edildiğini ve sonuçları anlamasını ve değerlendirmesini kolaylaştırır.",

            "What Is SPSS?": "SPSS Nedir?",
            "Introduction to SPSS": "SPSS'e Giriş",
            "SPSS (Statistical Package for the Social Sciences) is a statistical analysis software developed for social sciences. However, over time, it has become widely used across various scientific fields, including biostatistics, due to its broad data analysis capabilities. SPSS allows researchers to easily analyze complex datasets with its user-friendly interface and powerful analysis features.": "SPSS (Statistical Package for the Social Sciences), sosyal bilimler için geliştirilmiş bir istatistiksel analiz yazılımıdır. Ancak zamanla, geniş veri analiz kabiliyetleri sayesinde biyoistatistik de dahil olmak üzere çeşitli bilimsel alanlarda yaygın olarak kullanılmaya başlanmıştır. SPSS, kullanıcı dostu arayüzü ve güçlü analiz yetenekleri ile araştırmacıların karmaşık veri setlerini kolayca analiz etmelerine olanak tanır.",
            "SPSS and Its Use: Importance and Scope in Biostatistics": "SPSS ve Kullanımı: Biyoistatistikteki Önemi ve Kapsamı",
            "SPSS, a leading tool for data analysis in scientific research, holds significant importance in biostatistics. At MedimetricMinds, we aim to share the use and importance of SPSS in biostatistics.": "Bilimsel araştırmalarda kullanılan veri analiz araçlarının başında gelen SPSS (Statistical Package for the Social Sciences), biyoistatistikte önemli bir yer tutar. MedimetricMinds olarak, bu yazımızda SPSS'in biyoistatistikteki kullanımını ve önemini sizlerle paylaşmak istiyoruz.",
            "Uses of SPSS in Biostatistics": "SPSS'in Biyoistatistikte Kullanımı",
            "Biostatistics requires data analysis in health and biology fields. SPSS provides a powerful set of tools for conducting these analyses. Here are the areas where SPSS is used in biostatistics:": "Biyoistatistik, sağlık ve biyoloji alanlarında veri analizi yapmayı gerektiren bir disiplindir. SPSS, bu analizleri gerçekleştirmek için güçlü bir araç seti sunar. İşte SPSS'in biyoistatistikte kullanım alanları:",
            "1. Data Management: SPSS facilitates the management and organization of large datasets. Data cleaning, handling missing values, and data transformation can be performed quickly.": "1. Veri Yönetimi: SPSS, büyük veri setlerinin yönetimini ve düzenlenmesini kolaylaştırır. Verilerin temizlenmesi, eksik verilerin işlenmesi ve veri dönüştürme işlemleri hızlı bir şekilde yapılabilir.",
            "2. Descriptive Statistics: Researchers can use descriptive statistics (mean, median, standard deviation, etc.) to understand the general characteristics of datasets. SPSS automatically performs these calculations and presents them to the user.": "2. Tanımlayıcı İstatistikler: Araştırmacılar, veri setlerinin genel özelliklerini anlamak için tanımlayıcı istatistikleri (ortalama, medyan, standart sapma vb.) kullanabilirler. SPSS, bu tür hesaplamaları otomatik olarak yapar ve kullanıcıya sunar.",
            "3. Hypothesis Testing: Biostatistics involves testing hypotheses. SPSS performs various hypothesis tests, such as t-tests, ANOVA, and chi-square tests, quickly and accurately.": "3. Hipotez Testleri: Biyoistatistik, hipotezlerin test edilmesini gerektirir. SPSS, t-testi, ANOVA, ki-kare testi gibi çeşitli hipotez testlerini hızlı ve doğru bir şekilde gerçekleştirir.",
            "4. Regression Analysis: SPSS enables the examination of relationships between variables through simple and multiple regression analyses. This is crucial for studies like identifying risk factors in health fields.": "4. Regresyon Analizleri: SPSS, basit ve çoklu regresyon analizleri ile değişkenler arasındaki ilişkileri incelemeyi sağlar. Bu, sağlık alanında risk faktörlerinin belirlenmesi gibi çalışmalar için kritik öneme sahiptir.",
            "5. Survival Analysis: SPSS supports survival analyses commonly used in health research. These analyses are used to examine the progression of diseases and the effectiveness of treatments.": "5. Sağkalım Analizleri: SPSS, sağlık araştırmalarında yaygın olarak kullanılan sağkalım analizlerini destekler. Bu analizler, hastalıkların seyri ve tedavi etkinliği gibi konuları incelemek için kullanılır.",
            "6. Visualization: SPSS provides various graphic and plotting tools for data visualization. This helps present results in a clearer and more effective manner.": "6. Görselleştirme: SPSS, verilerin görselleştirilmesi için çeşitli grafik ve çizim araçları sunar. Bu, sonuçların daha anlaşılır ve etkili bir şekilde sunulmasını sağlar.",
            "Importance of SPSS in Biostatistics": "SPSS'in Biyoistatistikteki Önemi",
            "The importance of SPSS in biostatistics stems from its many advantages:": "SPSS'in biyoistatistikteki önemi, sağladığı birçok avantajdan kaynaklanmaktadır:",
            "1. User-Friendly Interface: SPSS offers complex statistical analyses through a user-friendly interface. This allows users with limited biostatistics knowledge to perform effective analyses.": "1. Kullanıcı Dostu Arayüz: SPSS, karmaşık istatistiksel analizleri kullanıcı dostu bir arayüz ile sunar. Bu, biyoistatistik bilgisi sınırlı olan kullanıcıların bile etkili analizler yapabilmesini sağlar.",
            "2. Powerful Data Analysis Capacity: SPSS can analyze large datasets quickly and efficiently. This is a significant advantage for researchers working with large databases in health fields.": "2. Güçlü Veri Analiz Kapasitesi: SPSS, büyük veri setlerini hızlı ve etkili bir şekilde analiz edebilir. Bu, sağlık alanında büyük veri tabanları ile çalışan araştırmacılar için büyük bir avantajdır.",
            "3. Comprehensive Statistical Toolset: SPSS offers a wide range of analysis options, from descriptive statistics to complex modeling. This helps meet various research needs in biostatistics.": "3. Kapsamlı İstatistiksel Araç Seti: SPSS, tanımlayıcı istatistiklerden karmaşık modellemelere kadar geniş bir analiz yelpazesi sunar. Bu, biyoistatistik alanında farklı türdeki araştırma ihtiyaçlarını karşılamaya yardımcı olur.",
            "4. Reliability and Validity: SPSS has proven itself over the years in producing reliable and valid results in scientific research. This ensures high reliability in biostatistical analyses.": "4. Güvenilirlik ve Geçerlilik: SPSS, yıllardır bilimsel araştırmalarda güvenilir ve geçerli sonuçlar üretme konusunda kendini kanıtlamıştır. Bu, biyoistatistik analizlerinde yüksek güvenilirlik sağlar.",
            "5. Quality of Tables and Graphs: SPSS provides the capability to create high-quality, easily understandable tables and graphs. This facilitates the visual presentation of biostatistical results in academic papers and theses. Data visualization helps readers quickly grasp key findings and focus on the main points of the study.": "5. Tablo ve Grafiklerin Kalitesi: SPSS, yüksek kaliteli ve kolay anlaşılır tablo ve grafikler oluşturma imkânı sunar. Bu, akademik makalelerde ve tezlerde biyoistatistik sonuçlarının görsel olarak etkili bir şekilde sunulmasını sağlar. Verilerin görselleştirilmesi, okuyucuların önemli bulguları hızlıca kavramalarını ve çalışmanın ana noktalarına odaklanmalarını kolaylaştırır.",
            "Role of SPSS in Academic Work": "Akademik Çalışmalarda SPSS'in Rolü",
            "SPSS is a crucial indicator for academic studies and theses. However, researchers today wish to focus more on their research topics rather than just data analysis. In this regard, expert teams proficient in comprehensive data processing programs like SPSS help lighten the researchers' workload. At MedimetricMinds, we enhance your research with our support in this area, alleviating the burden in biostatistics.": "SPSS, akademik çalışmalar ve tezler için önemli bir göstergedir. Ancak günümüzde araştırmacılar, sadece veri analizine odaklanmak yerine araştırma konularına daha fazla yoğunlaşmak istemektedir. Bu noktada, SPSS gibi kapsamlı veri işleme programlarını etkin bir şekilde kullanabilen uzman ekipler, araştırmacıların yükünü hafifletmektedir. Medimetricminds olarak, bu alanda sunduğumuz destekle, araştırmalarınıza güç kazandırıyor ve biyoistatistik noktasında yükünüzü hafifletiyoruz.",
            "In manuscript writing, effectively presenting biostatistical results is crucial. In scientific papers where word usage is limited, some results need to be highlighted and communicated to the reader efficiently. Therefore, the visual quality of tables and graphs must be high and easily understandable. SPSS provides the tools necessary to meet these requirements and enables effective visualization of your data.": "Makale yazımında, elde edilen biyoistatistiksel sonuçların etkili bir şekilde sunulması büyük önem taşır. Kelime kullanım sayısının sınırlı olduğu bilimsel makalelerde, bazı sonuçların özellikle vurgulanması ve okuyucuya kolayca iletilmesi gerekir. Bu nedenle, tablo ve grafiklerin görsel niteliklerinin yüksek kalitede ve hedefe yönelik olarak kolay anlaşılır olması esastır. SPSS, bu gereksinimleri karşılayacak araçları sağlar ve verilerinizi etkili bir şekilde görselleştirmenize olanak tanır.",

            "References: Correct and Effective Use in Scientific Articles": "Referanslar: Bilimsel Makalelerde Doğru ve Etkili Kullanım",
            "Introduction to References": "Referanslara Giriş",
            "Scientific articles heavily rely on references to ensure the credibility and validity of the research. However, the format and style of references can vary depending on the requirements of the journal where the article is submitted. The International Committee of Medical Journal Editors (ICMJE) recommends that authors use original sources as much as possible in their most recent guidelines. Therefore, it is important to follow these guidelines when preparing your references. You can find detailed information [here](https://www.icmje.org/icmje-recommendations.pdf).": "Bilimsel makaleler, araştırmanın güvenilirliğini ve geçerliliğini sağlamak için referanslara büyük ölçüde bağımlıdır. Ancak, referansların formatı ve stili, yayımlanacak derginin gereksinimlerine göre değişiklik göstermektedir. Uluslararası Tıp Dergisi Editörleri Komitesi (International Committee of Medical Journal Editors - ICMJE), en son tavsiyelerinde yazarların mümkün olduğunca orijinal kaynaklardan alıntı yapmalarını önermektedir. Bu nedenle, referanslarınızı hazırlarken bu komitenin yönergelerini takip etmeniz önemlidir. Detaylı bilgiye [buradan ulaşabilirsiniz](https://www.icmje.org/icmje-recommendations.pdf).",
            "Additionally, resources such as PubMed and the U.S. National Library of Medicine can help with reference formatting. PubMed provides [formatting examples for different reference types](https://www.nlm.nih.gov/bsd/uniform_requirements.html), and the U.S. National Library of Medicine’s [eBook: Citing Medicine](https://www.ncbi.nlm.nih.gov/books/NBK7256/) offers assistance with citation rules.": "Ayrıca, PubMed ve ABD Ulusal Tıp Kütüphanesi (U.S. National Library of Medicine) tarafından sağlanan kaynaklar da referansların biçimlendirilmesi konusunda yardımcı olabilir. PubMed'in [referans türleri için biçimlendirme örnekleri](https://www.nlm.nih.gov/bsd/uniform_requirements.html) ve ABD Ulusal Tıp Kütüphanesi'nin [e-Kitabı: Citing Medicine](https://www.ncbi.nlm.nih.gov/books/NBK7256/) referanslarınızın doğru bir şekilde formatlanmasına yardımcı olabilir.",
            "Writing and Managing References": "Referansların Yazımı ve Yönetimi",
            "Authors can manually prepare references following specific guidelines. However, tracking changes in the order of references and the growth of the manuscript can make it challenging to maintain the integrity of the content. To assist with this, various citation management software tools are available. These tools help you organize, track, and generate bibliographies. Some tools, like EndNote, Refworks, Mendeley, and Zotero, offer additional features such as in-text citation plugins, the ability to add full-text PDFs, and collaboration options for sharing bibliographies with co-authors.": "Yazarlar referansları kendileri de belirli kurallara göre yazabilir. Ancak, makale metninin büyümesi ve kaynakların sıralamasındaki değişikliklerin takibi, bazen metnin anlam bütünlüğünü korumayı zorlaştırabilir. Bu nedenle, birçok alıntı yazılımı, referansları düzenlemenize, takip etmenize ve kaynakçalar oluşturmanıza yardımcı olabilir. Bu yazılımlar arasında EndNote, Refworks, Mendeley ve Zotero bulunmaktadır. Bu araçlar, kağıt içi alıntılar için eklentiler sunar, alıntılara tam metin PDF’ler ekleme olanağı sağlar ve kaynakçalarınızı ortak çalışanlarla paylaşmanıza yardımcı olur.",
            "Common Reference Types in Medical Articles": "Tıbbi Makalelerde Kullanılan Referans Türleri",
            "Common reference types used in medical articles include:": "Tıbbi makalelerde yaygın olarak kullanılan referans türleri şunlardır:",
            "- Journal articles": "- Dergi makaleleri",
            "- Books": "- Kitaplar",
            "- Book chapters": "- Kitap bölümleri",
            "- Posters or oral presentation texts published in conference proceedings": "- Ulusal veya uluslararası kongre kitapçıklarında yayınlanan posterler ve sözlü sunum metinleri",
            "- Web pages": "- Web sayfaları",
            "- Theses": "- Tezler",
            "Each journal has its own format changes, punctuation rules, and arrangements between sections. The formatting of each section of a reference may include punctuation placement, bold and italic enhancements, alphabetical or sequential ordering of references, and citation styles within the text.": "Her derginin kendi format değişiklikleri, noktalama işaretlerinin kullanımı ve bölümler arasındaki düzen farklı olabilir. Bir referansın her bölümündeki stilin biçimlendirilmesi, seçilen noktalama işaretlerinin yerleştirilmesini, kalın ve italik vurgulamaları, referansların alfabetik sırasını veya sıra ile sıralamasını ve metindeki alıntı stilini içerebilir.",
            "Understanding and Reading Article References": "Makale Künyesi ve Okunması",
            "Each article has a reference format, and understanding how to read this format is essential for quick access to the article in library indices or on the journal’s website. For example, \"INJURY 2016;47(9):1945-1950\" indicates the year 2016, volume 47, issue 9, starting page 1945, and ending page 1950. Correct interpretation of this information ensures quick access to the article.": "Her makalenin bir künyesi bulunmaktadır ve bu künye, makalenin kütüphane indekslerinde veya internet ortamında bilimsel dergilerin web sayfalarında hızlı bir şekilde erişilmesi için önemlidir. Örneğin, \"INJURY 2016;47(9):1945-1950\" künyesi, 2016 yılı, 47. cilt, 9. sayı, 1945 başlangıç sayfası ve 1950 bitiş sayfasını ifade eder. Bu bilgilerin doğru bir şekilde anlaşılması, makaleye en hızlı erişimi sağlar.",
            "Proper and effective use of references in your scientific article enhances the quality and credibility of your work. Therefore, careful attention to reference preparation and management is crucial.": "Bilimsel makalenizde referansların doğru ve etkili bir şekilde kullanılması, çalışmanızın kalitesini ve güvenilirliğini artıracaktır. Bu nedenle, referansları hazırlarken ve yönetirken dikkatli olmanız büyük önem taşır.",

            "Plagiarism: Understanding and Avoiding It": "İntihal (Plagiarism): Tanım ve Kaçınma Yolları",
            "What is Plagiarism?": "İntihal Nedir?",
            "Plagiarism is a critical issue in the academic and professional writing world. It involves presenting someone else's work or ideas as your own. It is important to note that plagiarism is not limited to copying and pasting; it also includes stealing ideas and verbal patterns.": "İntihal, akademik ve profesyonel yazım dünyasında ciddiye alınması gereken bir konudur. İntihal, başkalarının çalışmalarını veya fikirlerini kendi eserinize dahil etmek ve bunu kendi orijinal çalışmanız gibi sunmak anlamına gelir. Bu durumun yalnızca kopyala-yapıştır yöntemiyle sınırlı olmadığını, aynı zamanda fikirlerin ve konuşma kalıplarının çalınmasını da kapsadığını bilmek önemlidir.",
            "Definition and Causes of Plagiarism": "İntihalin Tanımı ve Nedenleri",
            "The term “plagiarism” is defined in dictionaries as “presenting someone else’s work or ideas as your own.” The primary reasons for plagiarism in medical articles or other academic texts include laziness, procrastination, lack of creativity, understanding, or knowledge about the topic.": "İntihal terimi, sözlüklerde “başka birinin çalışmasını veya fikirlerini kendinizinmiş gibi sunmak” olarak tanımlanır. Tıbbi makalelerde veya diğer akademik metinlerde intihalin başlıca nedenleri arasında tembellik, gecikme, yaratıcılık eksikliği, konuya dair anlayış veya bilgi eksikliği sayılabilir.",
            "How to Avoid Plagiarism in Medical Writing": "Tıbbi Metinlerde İntihalden Kaçınma Yolları",
            "To avoid plagiarism in medical writing, consider the following approaches:": "Tıbbi bir metin yazarken intihal yapmaktan kaçınmak için aşağıdaki yöntemleri uygulayabilirsiniz:",
            "1. Detecting and Rewriting Content: To avoid plagiarism, first identify any potential issues in your text. Then, rephrase the original ideas in your own words while properly citing the original source. The goal of rewriting is to present the idea in your own words while giving appropriate credit to the original author.": "1. İntihali Bulmak ve İçeriği Yeniden Yazmak: İntihalden kaçınmak için, önce metninizdeki intihal risklerini tespit etmelisiniz. Bunun ardından, orijinal fikirleri kendi kelimelerinizle ifade etmeye çalışmalısınız. Yeniden yazma sürecinde amacınız, fikri kendi cümlelerinizle sunmak ve orijinal kaynağa uygun bir şekilde atıfta bulunmak olmalıdır.",
            "2. Plagiarism Checkers: To effectively avoid plagiarism, use “plagiarism checkers.” These tools compare your text with online sources to identify any similarities and highlight matching sections. iThenticate is a widely used tool among these, providing a report that indicates the points of plagiarism and the sources of the plagiarized content. Additionally, it provides the percentage of plagiarism in the text.": "2. İntihal Denetleyicileri: İntihalden etkili bir şekilde kaçınmak için “intihal denetleyici” araçlardan faydalanabilirsiniz. Bu araçlar, metninizi çevrimiçi kaynaklarla karşılaştırarak benzerlikleri belirler ve eşleşen kısımları vurgular. iThenticate, bu araçlardan en popüler olanıdır ve metindeki intihal noktalarını işaretleyerek bir rapor sunar. Ayrıca, metindeki intihal oranını yüzdelik olarak gösterir.",
            "3. Paraphrasing Tools: Paraphrasing involves changing the words and sentences of content without altering its original meaning and purpose. You can do this manually or use online paraphrasing tools to help with the process.": "3. Yorumlama Araçları: İçeriğinizi başka kelimelerle ifade etmenin iki ana yolu vardır: Manuel olarak veya çevrimiçi araçlar kullanarak. Yorumlama araçları, metnin orijinal anlamını değiştirmeden kelimeleri ve cümleleri değiştirmenize yardımcı olur.",
            "Avoiding Plagiarism with MediMetricMinds": "MediMetricMinds İle İntihalden Kaçınma",
            "At MediMetricMinds, we check your medical texts for plagiarism using plagiarism detection software and provide you with a detailed report. Generally, medical journals expect the plagiarism rate to be below 20%. Upon request, we help reduce the similarity rate in your articles to below 20% using paraphrasing tools and our experts’ input.": "MediMetricMinds olarak, yazmış olduğunuz tıbbi metinleri intihal yazılımları ile kontrol ederek size detaylı bir rapor sunuyoruz. Genel olarak, tıbbi dergiler intihal oranının %20’nin altında olmasını bekler. Talebiniz doğrultusunda, intihalden kaçınma amacıyla makalelerinizdeki benzerlik oranını, yorumlama araçları ve uzmanlarımızın katkılarıyla %20’nin altına indiriyoruz.",
            "Avoiding plagiarism is crucial for maintaining credibility and ensuring the originality of your work in both academic and professional settings. Therefore, taking the necessary steps to ensure your texts are free from plagiarism is essential.": "İntihalden kaçınmak, hem akademik hem de profesyonel dünyada güvenilirliğinizi artırır ve çalışmanızın özgünlüğünü korur. Bu nedenle, metinlerinizin intihalden arındırılmış ve özgün olması için gerekli önlemleri almanız önemlidir.",

            "Expert Consulting Services in Statistics and Biostatistics by MedimetricMinds": "MedimetricMinds'ın İstatistik ve Biyoistatistik Alanındaki Uzman Danışmanlık Hizmetleri",
            "What MedimetricMinds Offers": "MedimetricMinds Olarak Neler Sunuyoruz?",
            "At MedimetricMinds, we provide expert consulting services in statistics and biostatistics. From statistical hypothesis testing to complex modeling, we offer comprehensive support across a wide range of areas. Our consulting services are delivered by experienced clinicians who guide you through every stage of your research. Accurate data analysis and interpretation are crucial for the success of your research.": "MedimetricMinds olarak, istatistik ve biyoistatistik alanlarında kapsamlı danışmanlık hizmetleri sunuyoruz. İstatistiksel hipotez testlerinden karmaşık modellemelere kadar geniş bir yelpazede destek sağlıyoruz. Danışmanlık hizmetlerimiz, araştırmanızın her aşamasında size rehberlik edecek deneyimli klinisyenlerden oluşmaktadır. Verilerinizin doğru bir şekilde analiz edilmesi ve yorumlanması, araştırmanızın başarısı için kritik öneme sahiptir.",
            "MedimetricMinds' SPSS Consulting Services": "MedimetricMinds Olarak SPSS Danışmanlık Hizmetlerimiz",
            "At MedimetricMinds, we offer expert consulting services in SPSS and biostatistics. Our services include:": "MedimetricMinds olarak, SPSS ve biyoistatistik alanlarında uzman danışmanlık hizmetleri sunuyoruz. Hizmetlerimiz şunları içerir:",
            "1. Data Analysis and Reporting: We analyze your datasets using SPSS and prepare reports that meet scientific standards.": "1. Veri Analizi ve Raporlama: SPSS kullanarak veri setlerinizi analiz ediyor ve bilimsel standartlara uygun raporlar hazırlıyoruz.",
            "2. Training and Support: We conduct training sessions on SPSS and biostatistics, helping researchers effectively use these tools.": "2. Eğitim ve Destek: SPSS ve biyoistatistik konularında eğitimler düzenliyor ve araştırmacıların bu araçları etkin bir şekilde kullanmalarını sağlıyoruz.",
            "3. Customized Solutions: We provide customized SPSS solutions tailored to the needs of your research.": "3. Özelleştirilmiş Çözümler: Araştırmanızın ihtiyaçlarına göre özelleştirilmiş SPSS çözümleri sunuyoruz.",
            "Why Choose MedimetricMinds?": "Neden MedimetricMinds?",
            "1. Expert Team: Our team consists of clinicians with academic and professional experience in biostatistics, providing the most accurate analyses.": "1. Uzman Kadro: Akademik ve profesyonel deneyime sahip, biyoistatistik konusunda ilgi ve ileri düzeyde beceriye sahip klinisyenlerden oluşan ekibimizle en doğru analizleri sunuyoruz.",
            "2. Comprehensive Services: We offer thorough consulting throughout the data collection, analysis, interpretation, and reporting processes.": "2. Kapsamlı Hizmetler: Veri toplama, analiz, yorumlama ve raporlama süreçlerinde kapsamlı danışmanlık sağlıyoruz.",
            "3. Customized Approach: We provide tailored solutions based on the unique needs of your research.": "3. Özelleştirilmiş Yaklaşım: Araştırmanızın özgün ihtiyaçlarına göre özelleştirilmiş çözümler sunuyoruz.",
            "4. Training and Support: We offer training on statistical methods and ensure researchers understand these processes.": "4. Eğitim ve Destek: İstatistiksel yöntemler konusunda eğitimler veriyor ve araştırmacıların bu süreçleri anlamalarını sağlıyoruz.",
            "Consult with Our Experts": "Danışmanımız ile İletişim",
            "Understanding and interpreting statistical data is crucial. However, over time, this knowledge can become outdated or new information may need to be presented. MedimetricMinds supports you through online courses categorized into three areas to enhance your academic presentations and grasp of results.": "İstatistiksel veri yorumlamalarını doğru bir şekilde yapabilmek önemlidir. Ancak, zamanla bu bilgilerin güncellenmesi veya yeniden hatırlatılması gerekebilir. MedimetricMinds olarak, sizi desteklemek için üç farklı kategoride online kurslar sunuyoruz. Bu kurslar, akademik makalelerinizde daha sağlıklı sunumlar yapmanıza ve sonuçlarınıza daha hâkim olmanıza yardımcı olacaktır.",
            "MedimetricMinds Online Consulting Services": "MedimetricMinds ile Online Danışmanlık Hizmetleri",
            "In the rapidly changing modern world, the effectiveness and accuracy of your research are directly linked to the quality of your consulting services. At MedimetricMinds, we offer top-notch online consulting services to provide the support you need in statistics and biostatistics.": "Modern çağın hızla değişen dünyasında, araştırmaların etkinliği ve doğruluğu, sahip olduğunuz danışmanlık hizmetleriyle doğrudan bağlantılıdır. MedimetricMinds olarak, size en iyi online danışmanlık hizmetlerini sunarak, istatistik ve biyoistatistik konularında ihtiyaç duyduğunuz desteği sağlıyoruz.",
            "Advantages of Online Consulting Services:": "Online Danışmanlık Hizmetlerinin Avantajları:",
            "1. Easy Access and Quick Solutions: Reach our expert consultants from anywhere with an internet connection, eliminating geographical limitations.": "1. Kolay Erişim, Hızlı Çözüm: İnternet bağlantınızın olduğu her yerden uzman danışmanlarımıza ulaşabilirsiniz. Online platformlarımız sayesinde, coğrafi sınırlamaları ortadan kaldırarak, nerede olursanız olun ihtiyacınız olan desteği alabilirsiniz.",
            "2. Save Time and Resources: Focus on your academic research without the need for physical meetings. We minimize time loss with SPSS and adapt to your busy schedule.": "2. Zamandan ve Mekandan Tasarruf: Fiziksel görüşmelere gerek kalmadan, zaman kaybetmeden akademik araştırmalarınıza odaklanabilirsiniz. SPSS gibi veri işleme araçlarında zaman kaybını minimuma indirgeyerek ve yoğun programınıza uyum sağlayarak size, en uygun zamanlarda hizmet veriyoruz.",
            "3. Work with Expert Teams: Our team of experts, with years of experience in statistics and biostatistics, provides clear and effective solutions to even the most complex questions.": "3. Alanında Uzman Kadro ile Çalışın: İstatistik ve biyoistatistik alanlarında yılların deneyimine sahip uzman ekibimiz, en karmaşık sorularınıza bile net ve etkili çözümler sunar. Uzmanlarımız, projelerinizin başarısını garanti altına almak için burada.",
            "4. Personalized and Targeted Services: We develop strategies tailored to the specific needs of your research, ensuring you achieve success.": "4. Kişiselleştirilmiş ve Hedefe Yönelik Hizmet: Her araştırmanın kendine özgü ihtiyaçları vardır. Bu nedenle, size özel danışmanlık hizmetleri sunarak, projenizin gereksinimlerine uygun stratejiler geliştiriyoruz. Başarıya ulaşmanız için yanınızdayız.",
            "Conclusion": "Sonuç",
            "SPSS is an indispensable tool in biostatistics. Its user-friendly interface, powerful data analysis capabilities, and comprehensive statistical tools enable researchers to perform even the most complex analyses with ease. Additionally, SPSS's high-quality tables and charts enhance the visual and analytical aspects of your academic work. At MedimetricMinds, we are here to provide you with the best consulting services in SPSS and biostatistics. For more information and to benefit from our consulting services, please contact us.": "SPSS, biyoistatistikte vazgeçilmez bir araçtır. Kullanıcı dostu arayüzü, güçlü veri analiz kapasitesi ve kapsamlı istatistiksel araç seti ile araştırmacıların en karmaşık veri analizlerini bile kolayca gerçekleştirmelerini sağlar. Ayrıca, SPSS'in sunduğu yüksek kaliteli tablo ve grafikler, akademik çalışmalarınızın görsel ve analitik yönünü güçlendirir. MedimetricMinds olarak, SPSS ve biyoistatistik konularında size en iyi danışmanlık hizmetlerini sunmak için buradayız. Daha fazla bilgi ve danışmanlık hizmetlerimizden yararlanmak için bizimle iletişime geçebilirsiniz.",
            "This blog post is designed to highlight MedimetricMinds' consulting services and provide information about the importance of SPSS in biostatistics. Feel free to visit our website for any questions and needs.": "Bu blog yazısı, MedimetricMinds'ın danışmanlık hizmetlerini vurgulamak ve SPSS'in biyoistatistikteki önemini açıklamak amacıyla hazırlanmıştır. Her türlü soru ve ihtiyaçlarınız için web sitemizden bize ulaşabilirsiniz."



        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en", // Default language
        fallbackLng: "en",
        keySeparator: false,
        interpolation: {
            escapeValue: false // React already safes from XSS
        }
    });

export default i18n;
