import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import MainBlog1 from "./Assets/MainBlog1.png";
import MainBlog2 from "./Assets/MainBlog2.png";
import MainBlog3 from "./Assets/MainBlog3.png";

import scrol11 from "./Assets/scrol11.png";
import scrol12 from "./Assets/scrol12.png";
import scrol13 from "./Assets/scrol13.png";
import scrol14 from "./Assets/scrol14.png";
import scrol15 from "./Assets/scrol15.png";
import scrol16 from "./Assets/scrol16.png";

import scrol21 from "./Assets/scrol21.png";
import scrol22 from "./Assets/scrol22.png";
import scrol23 from "./Assets/scrol23.png";
import scrol24 from "./Assets/scrol24.png";
import workPlan from "./Assets/workPlan.png";

import m1 from "./Assets/m1.png";
import m2 from "./Assets/m2.png";
import m3 from "./Assets/m3.png";
import m4 from "./Assets/m4.png";

import b1 from "./Biyoistatistikte/1.png";
import b2 from "./Biyoistatistikte/2.png";
import b3 from "./Biyoistatistikte/3.png";
import b4 from "./Biyoistatistikte/4.png";
import b5 from "./Biyoistatistikte/5.png";
import b6 from "./Biyoistatistikte/6.png";
import b7 from "./Biyoistatistikte/7.png";
import { FaUser } from 'react-icons/fa'; // Import the user icon from React Icons
import medimetricanimasyon from "./Assets/medimetricanimasyon.mp4";

import mainPageCalendar from "./Assets/mainPageCalendar.png";
import { m, motion } from 'framer-motion';

import statisticalanalysis from "./Assets/statistical-analysis.svg";
import workplanworklist from "./Assets/work-plan-work-list.svg";
import graphicsandtables from "./Assets/graphics-and-tables.svg"
import "./home.css";
import { useTranslation } from 'react-i18next';
const Home = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleMoreBlogsClick = () => {
        navigate('/blog');
    };
    const firstRowImages = [
        scrol11, scrol12, scrol13, scrol14, scrol15, scrol16, scrol21, scrol22, scrol23, scrol24
    ];
    const secondRowImages = [
        scrol21, scrol22, scrol23, scrol24
    ];

    const [selectedService, setSelectedService] = useState('workPlan');
    const renderImage = () => {
        switch (selectedService) {
            case 'workPlan':
                return <img src={workplanworklist} alt={t('workPlanTitle')} />;
            case 'graphicsTables':
                return <img src={graphicsandtables} alt={t('graphicsTablesTitle')} />;
            case 'statisticalAnalysis':
                return <img src={statisticalanalysis} alt={t('statisticalAnalysisTitle')} />;
            default:
                return null;
        }
    };
    const testimonials = [
        {
            name: 'Testimonial 1: Dr. Gürkan Demirdizen',
            position: 'Cardiovascular Surgery Resident',
            positionTr: 'Kalp ve Damar Cerrahisi Asistanı',
            date: '25.07.2024',
            image: '',
            rating: 5,
            text: 'Medimetricminds helped me with statistical analysis for my study. Based on the suggestions made by the journal referees based on statistical analysis, they wrote a very quick and good edit and answer letter. Finally, my paper was accepted and put out there. I\'d like to thank MediMetricMinds for their help.',
        },
        {
            name: 'Testimonial 2: Dr. Canan Hasbal',
            position: 'Paediatrician',
            positionTr: 'Çocuk Sağlığı ve Hastalıkları uzmanı',
            date: '25.08.2022',
            image: '',
            rating: 5,
            text: 'I did not have much experience in clinical research. In a single face-to-face online meeting with the medimetrics expert team, we determined all the research steps I needed to do. The research process is long and laborious, but with a useful workplan, the process went more smoothly. Thanks a lot…',
        },
        {
            name: 'Testimonial 3: Dr. Damla Yürük',
            position: 'Anaesthesiologist',
            positionTr: 'Anesteziyoloji uzmanı',
            date: '28.06.2022',
            image: '',
            rating: 5,
            text: 'They quickly and easily provided me with the graphic pictures I needed for my business. I\'m grateful.',
        },
        {
            name: 'Testimonial 4: Dr. Derya Güner',
            position: 'Anaesthesiology and Reanimation Specialist',
            positionTr: 'Anesteziyoloji uzmanı',
            date: '06.11.2023',
            image: '',
            rating: 5,
            text: 'Hello everyone... Emrah Şişli had previously assisted me with some of my articles. He has been very understanding and has helped and guided me in many aspects of my work with a positive interaction. I thank him and wish him success in his new field.',
        },
        {
            name: 'Testimonial 5: Dr Ayben Beral',
            position: 'Paediatrician',
            positionTr: 'Pediatri uzmanı',
            date: '20.12.2022',
            image: '',
            rating: 5,
            text: 'I had the peer review of my article, which I completed for Medimetricminds, done. With the critical corrections they made, my article was accepted for publication by the journal in one go. It also cost much less than similar consultants in the market. Thank you.',
        },
        {
            name: 'Testimonial 6: Dr. Adriana Lopez',
            position: 'Dermatologist',
            positionTr: 'Cildiye uzmanı',
            date: '20.02.2023',
            image: '',
            rating: 5,
            text: 'Hello friends. The advisory team is working very cohesively, and everyone is fulfilling their responsibilities properly. My online face-to-face consultations were very productive. Apart from the support services I received, I can say that the consultants also broadened my horizons in terms of scientific research. I sincerely thank the entire team.',
        },
        {
            name: 'Testimonial 7: Dr. Duygu Demirdizen',
            position: 'Ophthalmologist',
            positionTr: 'Göz hastalıkları uzmanı',
            date: '14.06.2023',
            image: '',
            rating: 5,
            text: 'Hello... During my residency, I was directed to Medimetrics on the recommendation of a friend for my thesis. The meetings I had with the consultants were very productive. They provided all the necessary support on time, focusing on the goal. They even increased the value of my article with their guidance at certain points. I can say that my horizons have broadened in terms of scientific research. I recommend it...',
        },
        {
            name: 'Testimonial 8: Dr. Neslihan Eskut',
            position: 'Neurologist',
            positionTr: 'Nöroloji uzmanı',
            date: '11.07.2022',
            image: '',
            rating: 5,
            text: 'Hello. I sought their support in preparing a visual abstract along with statistical analysis for my research on neurogenic bladder. They are very positive and very sensitive about deadlines. I recommend it, thank you.',
        },
        {
            name: 'Testimonial 9: Op Dr Rauf Önder',
            position: 'Cardiovascular Surgeon',
            positionTr: 'Kalp Damar Cerrahisi Uzmanı',
            date: '19.07.2024',
            image: '',
            rating: 5,
            text: 'Hello. In my research on cardiac surgery, the consultant cardiac surgeon who was interested in me guided me during the online meeting on how to proceed with the article, even before I asked for it. I highly recommend that you meet with the consultants for free. Wishing everyone a smooth time...',
        }
    ];


    const blogs = [
        {
            id: 1,
            title: t('What Are Statistics and Biostatistics?'),
            date: '22 AUG 2024',
            image: b1,
            content: [
                {
                    heading: t('Introduction'),
                    paragraphs: [
                        t('We live in the age of data, and statistics and biostatistics, which are indispensable tools for scientific research, are crucial for researchers. At MedimetricMinds, we want to explain what these two disciplines are and how they are used.')
                    ],
                },
                {
                    heading: t('What Is Statistics?'),
                    paragraphs: [
                        t('Statistics is a discipline that encompasses the processes of collecting, analyzing, interpreting, and reporting data. For researchers, it is important to evaluate their data with the most appropriate analysis techniques to achieve accurate results. Statistics broadly includes measurements used to estimate data or population parameters. It generally refers to the process of processing and interpreting the data obtained.')
                    ],
                },
                {
                    heading: t('What Is Biostatistics?'),
                    paragraphs: [
                        t('Biostatistics is the application of statistical methods to biology, medicine, and health sciences. In Turkey, biostatistics is usually conducted as a main discipline within medical faculties, creating a strong connection between other medical sciences and biostatistics. This close relationship can sometimes lead to the perception that biostatistics is a completely different field from statistics.')
                    ],
                },
                {
                    heading: t('Differences and Commonalities Between Statistics and Biostatistics'),
                    paragraphs: [
                        t('The difference between statistics and biostatistics arises from the application area of the analyses. While statistics includes general data analysis techniques, biostatistics involves specialized analyses in fields such as medicine and biology. However, fundamental statistical techniques (such as t-tests, ANOVA, correlation analysis) are applied in both fields in the same way. Therefore, a statistician has the knowledge to analyze medical data, but they need detailed guidance on the relationships between the data.')
                    ],
                },
                {
                    heading: t('Biostatistical Analysis: Not a Simple Step'),
                    paragraphs: [
                        t('Many people may view biostatistical analysis as a simple step in the scientific research process. However, this is a misconception. Biostatistical analysis is one of the most critical stages of research and directly affects the reliability and validity of scientific research. Here are some factors explaining why this process is so important:'),
                        t('1. Determining Scientific Value: Researchers rely on biostatistical analyses to determine the scientific value of their work. Using the correct statistical methods ensures the reliability of the results obtained.'),
                        t('2. Organizing Groups: Biostatistics presents complex data groups in a more meaningful and organized manner, which facilitates the interpretation and understanding of the results.'),
                        t('3. Data Collection and Processing: How data is collected and processed directly affects the success of the analysis process. Biostatistics ensures the accuracy of data collection methods and the correct processing of data.'),
                        t('4. Reporting Results: Biostatistical analyses ensure that research results are reported accurately and effectively. This helps the scientific community understand and evaluate both how the findings were obtained and the results themselves.')
                    ],
                },
            ],
        },
        {
            id: 2,
            title: t('What Is SPSS?'),
            date: '23 AUG 2024',
            image: b2,
            content: [
                {
                    heading: t('Introduction to SPSS'),
                    paragraphs: [
                        t('SPSS (Statistical Package for the Social Sciences) is a statistical analysis software developed for social sciences. However, over time, it has become widely used across various scientific fields, including biostatistics, due to its broad data analysis capabilities. SPSS allows researchers to easily analyze complex datasets with its user-friendly interface and powerful analysis features.')
                    ],
                },
                {
                    heading: t('SPSS and Its Use: Importance and Scope in Biostatistics'),
                    paragraphs: [
                        t('SPSS, a leading tool for data analysis in scientific research, holds significant importance in biostatistics. At MedimetricMinds, we aim to share the use and importance of SPSS in biostatistics.')
                    ],
                },
                {
                    heading: t('Uses of SPSS in Biostatistics'),
                    paragraphs: [
                        t('Biostatistics requires data analysis in health and biology fields. SPSS provides a powerful set of tools for conducting these analyses. Here are the areas where SPSS is used in biostatistics:'),
                        t('1. Data Management: SPSS facilitates the management and organization of large datasets. Data cleaning, handling missing values, and data transformation can be performed quickly.'),
                        t('2. Descriptive Statistics: Researchers can use descriptive statistics (mean, median, standard deviation, etc.) to understand the general characteristics of datasets. SPSS automatically performs these calculations and presents them to the user.'),
                        t('3. Hypothesis Testing: Biostatistics involves testing hypotheses. SPSS performs various hypothesis tests, such as t-tests, ANOVA, and chi-square tests, quickly and accurately.'),
                        t('4. Regression Analysis: SPSS enables the examination of relationships between variables through simple and multiple regression analyses. This is crucial for studies like identifying risk factors in health fields.'),
                        t('5. Survival Analysis: SPSS supports survival analyses commonly used in health research. These analyses are used to examine the progression of diseases and the effectiveness of treatments.'),
                        t('6. Visualization: SPSS provides various graphic and plotting tools for data visualization. This helps present results in a clearer and more effective manner.')
                    ],
                },
                {
                    heading: t('Importance of SPSS in Biostatistics'),
                    paragraphs: [
                        t('The importance of SPSS in biostatistics stems from its many advantages:'),
                        t('1. User-Friendly Interface: SPSS offers complex statistical analyses through a user-friendly interface. This allows users with limited biostatistics knowledge to perform effective analyses.'),
                        t('2. Powerful Data Analysis Capacity: SPSS can analyze large datasets quickly and efficiently. This is a significant advantage for researchers working with large databases in health fields.'),
                        t('3. Comprehensive Statistical Toolset: SPSS offers a wide range of analysis options, from descriptive statistics to complex modeling. This helps meet various research needs in biostatistics.'),
                        t('4. Reliability and Validity: SPSS has proven itself over the years in producing reliable and valid results in scientific research. This ensures high reliability in biostatistical analyses.'),
                        t('5. Quality of Tables and Graphs: SPSS provides the capability to create high-quality, easily understandable tables and graphs. This facilitates the visual presentation of biostatistical results in academic papers and theses. Data visualization helps readers quickly grasp key findings and focus on the main points of the study.')
                    ],
                },
                {
                    heading: t('Role of SPSS in Academic Work'),
                    paragraphs: [
                        t('SPSS is a crucial indicator for academic studies and theses. However, researchers today wish to focus more on their research topics rather than just data analysis. In this regard, expert teams proficient in comprehensive data processing programs like SPSS help lighten the researchers\' workload. At MedimetricMinds, we enhance your research with our support in this area, alleviating the burden in biostatistics.'),
                        t('In manuscript writing, effectively presenting biostatistical results is crucial. In scientific papers where word usage is limited, some results need to be highlighted and communicated to the reader efficiently. Therefore, the visual quality of tables and graphs must be high and easily understandable. SPSS provides the tools necessary to meet these requirements and enables effective visualization of your data.')
                    ],
                },
            ],
        },
        {
            id: 3,
            title: t('References: Correct and Effective Use in Scientific Articles'),
            date: '24 AUG 2024',
            image: b3,
            content: [
                {
                    heading: t('Introduction to References'),
                    paragraphs: [
                        t('Scientific articles heavily rely on references to ensure the credibility and validity of the research. However, the format and style of references can vary depending on the requirements of the journal where the article is submitted. The International Committee of Medical Journal Editors (ICMJE) recommends that authors use original sources as much as possible in their most recent guidelines. Therefore, it is important to follow these guidelines when preparing your references. You can find detailed information [here](https://www.icmje.org/icmje-recommendations.pdf).'),
                        t('Additionally, resources such as PubMed and the U.S. National Library of Medicine can help with reference formatting. PubMed provides [formatting examples for different reference types](https://www.nlm.nih.gov/bsd/uniform_requirements.html), and the U.S. National Library of Medicine’s [eBook: Citing Medicine](https://www.ncbi.nlm.nih.gov/books/NBK7256/) offers assistance with citation rules.')
                    ],
                },
                {
                    heading: t('Writing and Managing References'),
                    paragraphs: [
                        t('Authors can manually prepare references following specific guidelines. However, tracking changes in the order of references and the growth of the manuscript can make it challenging to maintain the integrity of the content. To assist with this, various citation management software tools are available. These tools help you organize, track, and generate bibliographies. Some tools, like EndNote, Refworks, Mendeley, and Zotero, offer additional features such as in-text citation plugins, the ability to add full-text PDFs, and collaboration options for sharing bibliographies with co-authors.')
                    ],
                },
                {
                    heading: t('Common Reference Types in Medical Articles'),
                    paragraphs: [
                        t('Common reference types used in medical articles include:'),
                        t('- Journal articles'),
                        t('- Books'),
                        t('- Book chapters'),
                        t('- Posters or oral presentation texts published in conference proceedings'),
                        t('- Web pages'),
                        t('- Theses'),
                        t('Each journal has its own format changes, punctuation rules, and arrangements between sections. The formatting of each section of a reference may include punctuation placement, bold and italic enhancements, alphabetical or sequential ordering of references, and citation styles within the text.')
                    ],
                },
                {
                    heading: t('Understanding and Reading Article References'),
                    paragraphs: [
                        t('Each article has a reference format, and understanding how to read this format is essential for quick access to the article in library indices or on the journal’s website. For example, "INJURY 2016;47(9):1945-1950" indicates the year 2016, volume 47, issue 9, starting page 1945, and ending page 1950. Correct interpretation of this information ensures quick access to the article.'),
                        t('Proper and effective use of references in your scientific article enhances the quality and credibility of your work. Therefore, careful attention to reference preparation and management is crucial.')
                    ],
                },
            ],
        },
        {
            id: 4,
            title: t('Plagiarism: Understanding and Avoiding It'),
            date: '25 AUG 2024',
            image: b4,
            content: [
                {
                    heading: t('What is Plagiarism?'),
                    paragraphs: [
                        t('Plagiarism is a critical issue in the academic and professional writing world. It involves presenting someone else\'s work or ideas as your own. It is important to note that plagiarism is not limited to copying and pasting; it also includes stealing ideas and verbal patterns.')
                    ],
                },
                {
                    heading: t('Definition and Causes of Plagiarism'),
                    paragraphs: [
                        t('The term “plagiarism” is defined in dictionaries as “presenting someone else’s work or ideas as your own.” The primary reasons for plagiarism in medical articles or other academic texts include laziness, procrastination, lack of creativity, understanding, or knowledge about the topic.')
                    ],
                },
                {
                    heading: t('How to Avoid Plagiarism in Medical Writing'),
                    paragraphs: [
                        t('To avoid plagiarism in medical writing, consider the following approaches:'),
                        t('1. Detecting and Rewriting Content: To avoid plagiarism, first identify any potential issues in your text. Then, rephrase the original ideas in your own words while properly citing the original source. The goal of rewriting is to present the idea in your own words while giving appropriate credit to the original author.'),
                        t('2. Plagiarism Checkers: To effectively avoid plagiarism, use “plagiarism checkers.” These tools compare your text with online sources to identify any similarities and highlight matching sections. iThenticate is a widely used tool among these, providing a report that indicates the points of plagiarism and the sources of the plagiarized content. Additionally, it provides the percentage of plagiarism in the text.'),
                        t('3. Paraphrasing Tools: Paraphrasing involves changing the words and sentences of content without altering its original meaning and purpose. You can do this manually or use online paraphrasing tools to help with the process.')
                    ],
                },
                {
                    heading: t('Avoiding Plagiarism with MediMetricMinds'),
                    paragraphs: [
                        t('At MediMetricMinds, we check your medical texts for plagiarism using plagiarism detection software and provide you with a detailed report. Generally, medical journals expect the plagiarism rate to be below 20%. Upon request, we help reduce the similarity rate in your articles to below 20% using paraphrasing tools and our experts’ input.'),
                        t('Avoiding plagiarism is crucial for maintaining credibility and ensuring the originality of your work in both academic and professional settings. Therefore, taking the necessary steps to ensure your texts are free from plagiarism is essential.')
                    ],
                },
            ],
        },
        {
            id: 5,
            title: t('Expert Consulting Services in Statistics and Biostatistics by MedimetricMinds'),
            date: '27 AUG 2024',
            image: b5,
            content: [
                {
                    heading: t('What MedimetricMinds Offers'),
                    paragraphs: [
                        t('At MedimetricMinds, we provide expert consulting services in statistics and biostatistics. From statistical hypothesis testing to complex modeling, we offer comprehensive support across a wide range of areas. Our consulting services are delivered by experienced clinicians who guide you through every stage of your research. Accurate data analysis and interpretation are crucial for the success of your research.')
                    ],
                },
                {
                    heading: t('MedimetricMinds\' SPSS Consulting Services'),
                    paragraphs: [
                        t('At MedimetricMinds, we offer expert consulting services in SPSS and biostatistics. Our services include:'),
                        t('1. Data Analysis and Reporting: We analyze your datasets using SPSS and prepare reports that meet scientific standards.'),
                        t('2. Training and Support: We conduct training sessions on SPSS and biostatistics, helping researchers effectively use these tools.'),
                        t('3. Customized Solutions: We provide customized SPSS solutions tailored to the needs of your research.')
                    ],
                },
                {
                    heading: t('Why Choose MedimetricMinds?'),
                    paragraphs: [
                        t('1. Expert Team: Our team consists of clinicians with academic and professional experience in biostatistics, providing the most accurate analyses.'),
                        t('2. Comprehensive Services: We offer thorough consulting throughout the data collection, analysis, interpretation, and reporting processes.'),
                        t('3. Customized Approach: We provide tailored solutions based on the unique needs of your research.'),
                        t('4. Training and Support: We offer training on statistical methods and ensure researchers understand these processes.')
                    ],
                },
                {
                    heading: t('Consult with Our Experts'),
                    paragraphs: [
                        t('Understanding and interpreting statistical data is crucial. However, over time, this knowledge can become outdated or new information may need to be presented. MedimetricMinds supports you through online courses categorized into three areas to enhance your academic presentations and grasp of results.')
                    ],
                },
                {
                    heading: t('MedimetricMinds Online Consulting Services'),
                    paragraphs: [
                        t('In the rapidly changing modern world, the effectiveness and accuracy of your research are directly linked to the quality of your consulting services. At MedimetricMinds, we offer top-notch online consulting services to provide the support you need in statistics and biostatistics.'),
                        t('Advantages of Online Consulting Services:'),
                        t('1. Easy Access and Quick Solutions: Reach our expert consultants from anywhere with an internet connection, eliminating geographical limitations.'),
                        t('2. Save Time and Resources: Focus on your academic research without the need for physical meetings. We minimize time loss with SPSS and adapt to your busy schedule.'),
                        t('3. Work with Expert Teams: Our team of experts, with years of experience in statistics and biostatistics, provides clear and effective solutions to even the most complex questions.'),
                        t('4. Personalized and Targeted Services: We develop strategies tailored to the specific needs of your research, ensuring you achieve success.')
                    ],
                },
                {
                    heading: t('Conclusion'),
                    paragraphs: [
                        t('SPSS is an indispensable tool in biostatistics. Its user-friendly interface, powerful data analysis capabilities, and comprehensive statistical tools enable researchers to perform even the most complex analyses with ease. Additionally, SPSS\'s high-quality tables and charts enhance the visual and analytical aspects of your academic work. At MedimetricMinds, we are here to provide you with the best consulting services in SPSS and biostatistics. For more information and to benefit from our consulting services, please contact us.'),
                        t('This blog post is designed to highlight MedimetricMinds\' consulting services and provide information about the importance of SPSS in biostatistics. Feel free to visit our website for any questions and needs.')
                    ],
                },
            ],
        },
    ];
    const faqs = [
        { questionKey: 'faqQuestion1', answerKey: 'faqAnswer1' },
        { questionKey: 'faqQuestion2', answerKey: 'faqAnswer2' },
        { questionKey: 'faqQuestion3', answerKey: 'faqAnswer3' },
        { questionKey: 'faqQuestion4', answerKey: 'faqAnswer4' },
        { questionKey: 'faqQuestion5', answerKey: 'faqAnswer5' },
        { questionKey: 'faqQuestion6', answerKey: 'faqAnswer6' },
        { questionKey: 'faqQuestion7', answerKey: 'faqAnswer7' },
        { questionKey: 'faqQuestion8', answerKey: 'faqAnswer8' },
        { questionKey: 'faqQuestion9', answerKey: 'faqAnswer9' },
        { questionKey: 'faqQuestion10', answerKey: 'faqAnswer10' },
        { questionKey: 'faqQuestion11', answerKey: 'faqAnswer11' }, // New FAQ
        { questionKey: 'faqQuestion12', answerKey: 'faqAnswer12' }, // New FAQ
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const [currentIndex, setCurrentIndex] = useState(0);

    // Function to handle the change of testimonials
    const changeTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    // useEffect to start the interval to change the testimonial every 3 minutes (180000 ms)
    useEffect(() => {
        const interval = setInterval(changeTestimonial, 180000); // 180000 ms is 3 minutes

        return () => {
            clearInterval(interval); // Clean up the interval when the component unmounts
        };
    }, [testimonials.length]);


    const scrollRef = useRef(null);

    useEffect(() => {
        const scrollContainer = scrollRef.current;
        let scrollAmount = 0;
        const speed = 1; // Adjust the speed of the scroll
        let isCloning = false; // To avoid recursive cloning

        // Clone the content for seamless scroll
        const cloneContent = () => {
            if (isCloning) return;
            isCloning = true;
            const originalChildren = [...scrollContainer.children[0].children]; // Get original blogs
            originalChildren.forEach((child) => {
                const clone = child.cloneNode(true);
                scrollContainer.children[0].appendChild(clone);
            });
            isCloning = false;
        };

        const scroll = () => {
            scrollAmount += speed;
            if (scrollContainer) {
                scrollContainer.scrollLeft = scrollAmount;
                // Check if we've reached the cloned content
                if (scrollAmount >= scrollContainer.scrollWidth / 2) {
                    scrollAmount = 0; // Reset scroll position to the start
                }
            }
            requestAnimationFrame(scroll);
        };

        // Initialize scrolling and content cloning
        cloneContent();
        requestAnimationFrame(scroll);

        return () => cancelAnimationFrame(scroll);
    }, []);

    const scrollRefUsers = useRef(null);

    useEffect(() => {
        const scrollContainer = scrollRefUsers.current;
        let scrollAmount = 0;
        const speed = 1;
        let isCloning = false;

        // Function to clone testimonials for seamless scrolling
        const cloneContent = () => {
            if (isCloning) return;
            isCloning = true;
            const originalChildren = [...scrollContainer.children[0].children];
            originalChildren.forEach((child) => {
                const clone = child.cloneNode(true);
                scrollContainer.children[0].appendChild(clone);
            });
            isCloning = false;
        };

        const scroll = () => {
            scrollAmount += speed;
            if (scrollContainer) {
                scrollContainer.scrollLeft = scrollAmount;
                // Reset scroll when reaching the end of the original content
                if (scrollAmount >= scrollContainer.scrollWidth / 2) {
                    scrollAmount = 0;
                }
            }
            requestAnimationFrame(scroll);
        };

        // Initialize content cloning and scrolling
        cloneContent();
        requestAnimationFrame(scroll);

        return () => cancelAnimationFrame(scroll);
    }, []);
    const imgScrollRef = useRef(null);

    useEffect(() => {
        const scrollContainer = imgScrollRef.current;
        let scrollAmount = 0;
        const speed = 3; // Adjust the speed of the scroll
        let isCloning = false;

        // Clone the content for seamless scroll
        const cloneContent = () => {
            if (isCloning) return;
            isCloning = true;
            const originalChildren = [...scrollContainer.children[0].children]; // Get original images
            originalChildren.forEach((child) => {
                const clone = child.cloneNode(true);
                scrollContainer.children[0].appendChild(clone);
            });
            isCloning = false;
        };

        const scroll = () => {
            scrollAmount += speed;
            if (scrollContainer) {
                scrollContainer.scrollLeft = scrollAmount;
                // Check if we've reached the cloned content
                if (scrollAmount >= scrollContainer.scrollWidth / 2) {
                    scrollAmount = 0; // Reset scroll position to the start
                }
            }
            requestAnimationFrame(scroll);
        };

        // Initialize scrolling and content cloning
        cloneContent();
        requestAnimationFrame(scroll);

        return () => cancelAnimationFrame(scroll);
    }, []);


    return (
        <div className="bg-gray-50 min-h-screen overflow-x-hidden">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 flex justify-center min-h-screen">
                <div className="w-4/5 max-md:w-11/12 grid grid-cols-1 lg:grid-cols-2 gap-8">
                    {/* Left Column: Text and Buttons */}
                    <div className="left-div text-center lg:text-left">
                        <h1 className="text-4xl font-bold text-primary">
                            {t('unlockPotential')}
                        </h1>
                        <p className="mt-4 text-lg text-primary">
                            {t('dedicationText')}
                        </p>
                        <p className="mt-2 text-lg text-primary">
                            {t('balancingText')}
                        </p>
                        <div className="mt-8 flex justify-center lg:justify-start max-md:flex-col max-md:gap-2">
                            <button
                                className="flex items-center px-6 py-3 bg-teal-500 text-white rounded-full text-sm font-medium hover:bg-teal-600  max-md:w-3/4 max-md:mx-auto"
                                onClick={() => navigate('about')}
                            >
                                <span>{t('learnMore')}</span>
                            </button>
                            <button
                                className="lg:ml-4 flex items-center px-6 py-3 bg-teal-900 text-white rounded-full text-sm font-medium hover:bg-teal-800 max-md:w-3/4 max-md:mx-auto"
                                onClick={() => navigate('appointment')}
                            >
                                <span>{t('freeAppointment')}</span>
                                <svg className="ml-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                        fillRule="evenodd"
                                        d="M12.293 3.293a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L14 6.414V15a1 1 0 11-2 0V6.414L9.707 9.707a1 1 0 11-1.414-1.414l5-5z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    {/* Right Column: Images */}
                    <div className="right-div grid grid-cols-2 gap-4">
                        <motion.div
                            className="text-white w-1/3 p-6 rounded-lg shadow-lg flex flex-col items-center justify-center col-span-2 relative overflow-hidden"
                            initial={{ background: 'radial-gradient(circle, white 0%, #2596be 0%)' }}
                            whileHover={{
                                background: 'radial-gradient(circle, white 100%, #2596be 100%)',
                                transition: { duration: 1 }
                            }}
                        >
                            <img src={mainPageCalendar} className="w-[32px] h-[32px] relative z-10" />
                            <a href="contact" className="text-center text-primary text-lg max-lg:text-[13px] font-bold relative z-10">
                                {t('easyOnlineAppointment')}
                            </a>
                        </motion.div>
                        <img src={m3} alt="M1" className="w-full rounded-md lg:mb-1 lg:mt-[5vh]" />
                        <img src={m2} alt="M2" className="w-full max-md:mt-[-15vh] lg:mb-[10vh]" />
                        <img src={m1} alt="M1" className="w-full rounded-md mb-4" />
                        <img src={m4} alt="M4" className="w-full rounded-md mb-4 lg:mt-[-12vh] max-md:mt-[-10vh]" />
                    </div>
                </div>
            </div>

            <div className="relative w-full h-screen max-md:h-full max-md:min-h-[400px] bg-white">
                <video
                    className="absolute top-0 left-0 w-full h-full object-fit max-md:object-cover"
                    src={`${medimetricanimasyon}?t=${new Date().getTime()}`}
                    title=""
                    controls={false}
                    autoPlay
                    loop
                    playsInline
                    muted
                />
                <div className="relative z-10 flex flex-col items-center w-4/5 max-md:w-11/12 lg:w-2/5 mx-auto justify-center h-full text-primary p-4 max-md:p-8 bg-opacity-0">
                    <p className="text-xl md:text-2xl lg:text-4xl font-bold mb-4">{t('aboutUs')}</p>
                    <p className="text-xl md:text-2xl mb-2">{t('enhanceResearch')}</p>
                    <p className="text-center max-md:text-[12px] max-md:w-3/4 lg:text-xl max-md:custom-line-height">
                        {t('enhanceResearchDescription')}
                    </p>
                </div>
            </div>

            <div className="overflow-hidden w-full mx-auto p-6 max-md:w-full bg-white">
                <div className="overflow-hidden w-4/5 mx-auto p-6 max-md:w-full mt-[15vh] max-md:mt-[5vh]">
                    <p className='text-primary text-[16px]'>
                        {t('ourReferences')}
                    </p>
                    <p className='text-primary text-[36px] max-md:text-[24px]'>
                        {t('trustedByResearchers')}
                    </p>
                    <div className="scrolling-container mt-[10vh]" ref={imgScrollRef}>
                        <div className="flex gap-6">
                            {firstRowImages.map((img, index) => (
                                <img
                                    key={index}
                                    src={img}
                                    alt={`image-${index}`}
                                    className="h-36 flex-shrink-0 object-cover"
                                    style={{ width: 'auto' }}
                                />
                            ))}
                        </div>
                    </div>

                    <style jsx>{`
        .scrolling-container {
          overflow: hidden;
          white-space: nowrap;
          position: relative;
          width: 100%;
        }
        .scrolling-container img {
          flex-shrink: 0;
          height: 36px;
          margin-right: 2rem;
        }
      `}</style>
                </div>

            </div>

            <div className='w-full flex flex-col justify-center mt-[10vh] min-h-screen'>
                <h2 className="text-[16px] font-bold text-primary mb-4">{t('servicesTitle')}</h2>
                <p className="mb-6 text-[20px] text-primary w-3/5 mx-auto">{t('servicesDescription')}</p>
                <button
                    className="flex items-center px-6 py-3 w-[fit] mx-auto bg-teal-900 text-white rounded-full text-sm font-medium hover:bg-teal-600"
                    onClick={() => navigate('services')}
                >
                    <span>{t('moreServices')}</span>
                    <svg className="ml-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                        <path
                            fillRule="evenodd"
                            d="M12.293 3.293a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L14 6.414V15a1 1 0 11-2 0V6.414L9.707 9.707a1 1 0 11-1.414-1.414l5-5z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
                <div className="flex flex-col md:flex-row items-start md:items-center justify-between w-4/5 mx-auto px-4 sm:px-6 lg:px-8 py-12">
                    <div className="w-full md:w-1/2 md:mr-5">
                        <div className="space-y-6">
                            <button onClick={() => setSelectedService('workPlan')} className={`block p-2 text-left ${selectedService === 'workPlan' ? 'font-bold bg-[#f3f7fa]' : 'text-gray-600'}`}>
                                <h3 className="text-lg">{t('workPlanTitle')}</h3>
                                <p className="text-sm">{t('workPlanDescription')}</p>
                                <span className="text-teal-500">{t('learnMore')} →</span>
                                <div className="md:hidden mt-4">
                                    {selectedService === 'workPlan' && renderImage('workPlan')}
                                </div>
                            </button>
                            <button onClick={() => setSelectedService('graphicsTables')} className={`block p-2 text-left ${selectedService === 'graphicsTables' ? 'font-bold bg-[#f3f7fa]' : 'text-gray-600'}`}>
                                <h3 className="text-lg">{t('graphicsTablesTitle')}</h3>
                                <p className="text-sm">{t('graphicsTablesDescription')}</p>
                                <span className="text-teal-500">{t('learnMore')} →</span>
                                <div className="md:hidden mt-4">
                                    {selectedService === 'graphicsTables' && renderImage('graphicsTables')}
                                </div>
                            </button>
                            <button onClick={() => setSelectedService('statisticalAnalysis')} className={`block p-2 text-left ${selectedService === 'statisticalAnalysis' ? 'font-bold bg-[#f3f7fa]' : 'text-gray-600'}`}>
                                <h3 className="text-lg">{t('statisticalAnalysisTitle')}</h3>
                                <p className="text-sm">{t('statisticalAnalysisDescription')}</p>
                                <span className="text-teal-500">{t('learnMore')} →</span>
                                <div className="md:hidden mt-4">
                                    {selectedService === 'statisticalAnalysis' && renderImage('statisticalAnalysis')}
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="w-full md:w-2/5 mt-8 md:mt-0 flex justify-center items-center md:ml-5">
                        <div className="hidden md:block">
                            {renderImage(selectedService)}
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-4/5 py-12 max-md:w-full mx-auto">
                <div className="mx-auto text-left w-full">
                    <h2 className="text-3xl font-semibold text-center text-primary mb-8">{t('testimonials')}</h2>
                    <h3 className="text-xl font-semibold text-center text-primary mb-12"></h3>
                </div>
                <div className="max-w-full overflow-x-auto lg:pb-[10vh] max-md:pb-[5vh] hide-scrollbar" ref={scrollRefUsers}>
                    <div className="flex lg:gap-6 gap-4">
                        {testimonials.map((testimonial, index) => (
                            <div
                                key={index}
                                className="bg-white lg:w-[320px] w-[80vw] p-6 rounded-[32px] shadow-md flex-shrink-0"
                            >
                                <div className="flex items-center mb-4">
                                    <FaUser size={20} className="mr-2" />
                                    <div className="flex justify-between w-full">
                                        <h4 className="text-sm font-semibold text-[#004643]">{testimonial.name}</h4>
                                        <div className="flex">
                                            {Array.from({ length: testimonial.rating }).map((_, starIndex) => (
                                                <svg
                                                    key={starIndex}
                                                    className="w-4 h-4 text-red-500"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                >
                                                    <path d="M9.049.95a1 1 0 011.902 0l1.518 4.681a1 1 0 00.95.691h4.908a1 1 0 01.6 1.8l-3.974 2.888a1 1 0 00-.364 1.118l1.518 4.681a1 1 0 01-1.537 1.118L10 14.347l-3.974 2.888a1 1 0 01-1.537-1.118l1.518-4.681a1 1 0 00-.364-1.118L1.67 8.122a1 1 0 01.6-1.8h4.908a1 1 0 00.95-.691L9.049.95z" />
                                                </svg>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <p className="text-[#9EB1B0] text-[16px] text-left leading-relaxed tracking-wide">
                                    {t(testimonial.text)}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="w-4/5 py-12 max-md:w-full mx-auto">
                <div className="mx-auto text-left w-full">
                    <h2 className="text-3xl font-semibold text-center mb-4">{t('blog')}</h2>
                    <h3 className="text-xl font-semibold text-center mb-8 max-md:w-3/4 max-md:mx-auto">{t('blogDescription')}</h3>
                    <div className="flex justify-center mb-4">
                        <button
                            onClick={handleMoreBlogsClick}
                            className="bg-[#004643] text-white py-2 px-4 rounded-full"
                        >
                            {t('moreBlogs')} &rarr;
                        </button>
                    </div>
                    <div className="max-w-full overflow-x-auto lg:pb-[10vh] max-md:pb-[5vh] hide-scrollbar" ref={scrollRef}>
                        <div className="flex gap-6">
                            {blogs.map((blog, index) => (
                                <div key={index} className="bg-white p-6 rounded-[32px] shadow-md flex-shrink-0" style={{ width: 'calc(100% - 1rem)', maxWidth: '320px' }}>
                                    <img src={blog.image} alt={blog.title} className="w-full h-48 object-cover" />
                                    <div className="p-6">
                                        <div className="text-gray-500 mb-2">{blog.date}</div>
                                        <h4 className="text-lg font-semibold mb-2">{blog.title}</h4>
                                        <p className="text-gray-700">{blog.text}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <section className="w-4/5 py-12 max-md:w-full mx-auto">
                <div className="container mx-auto px-6">
                    <h2 className="text-3xl font-semibold mb-4 text-primary">{t('faq')}</h2>
                    <h3 className="text-xl font-semibold mb-8 text-primary">{t('faqDescription')}</h3>
                    <div className="space-y-4">

                        {faqs.map((faq, index) => (
                            <div key={index} className="border rounded-[32px] p-4 max-md:w-full">
                                <button
                                    onClick={() => toggleFAQ(index)}
                                    className="flex justify-between items-center w-full text-left text-primary text-[20px]"
                                >
                                    <span className="text-lg text-left font-medium max-md:text-[12px] w-4/5">
                                        {`${index + 1}. ${t(faq.questionKey)}`}
                                    </span>
                                    <svg
                                        className={`w-6 h-6 transform transition-transform ${activeIndex === index ? 'rotate-180' : ''} max-md:w-4 max-md:h-4`}
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                    </svg>
                                </button>
                                {activeIndex === index && (
                                    <div className="mt-2 text-primary">
                                        <p className='text-left text-[12px]'>{t(faq.answerKey)}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default Home;