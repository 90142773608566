import React from 'react';
import Footer from './Footer';
import story1 from "./Assets/story1.png";
import story2 from "./Assets/story2.png";
import story3 from "./Assets/story3.png";
import story4 from "./Assets/story4.png";
import story5 from "./Assets/story5.png";
import ConsultingIcon from './Assets/ConsultingIcon.png'; // Replace with your actual icon paths
import AnalysisIcon from './Assets/AnalysisIcon.png';
import GraphicsIcon from './Assets/GraphicsIcon.png';
import PlagiarismIcon from './Assets/PlagiarismIcon.png';
import ReferenceIcon from './Assets/ReferenceIcon.png';
import { useInView } from 'react-intersection-observer';
import ArdaImage from "./Assets/ArdaImage.png";
import EmrahImage from "./Assets/EmrahImage.png";
import MelisaImage from "./Assets/MelisaImage.png";
import OzanImage from "./Assets/OzanImage.png";
import useCountUp from './useCountUp'; // Import the custom hook
import { useTranslation } from 'react-i18next';

const Statistic = ({ end, title, description }) => {
    const { ref, inView } = useInView({ triggerOnce: true });
    const count = useCountUp(0, inView ? end : 0, 2000);

    return (
        <div ref={ref} className='h-[20vh] w-[15vw] text-left max-md:h-fit max-md:w-[300px]'>
            <h2 className="text-[64px] text-[#004643] font-bold">{count}+</h2>
            <p className='text-[#004643] text-[20px]'>{title}</p>
            {description && <p className='text-[#9EB1B0] text-[16px]'>{description}</p>}
        </div>
    );
};

const About = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="container mx-auto px-4 py-12 max-md:w-4/5 w-4/5">
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold">{t('discoverOurStory')}</h1>
                </div>
                <div className='flex flex-col md:flex-row mb-12'>
                    <div className="md:w-1/2 text-left mb-8 md:mb-0 md:pr-8">
                        <p className="text-lg text-[#9EB1B0] p-5">
                            {t('MediMetricMinds operates to support academic research and clinical trials. Our mission is to provide quality, flexible, and innovative solutions to professionals in the academic and clinical fields to facilitate their research processes and optimize their outcomes.')}
                        </p>
                        <img src={story3} alt="story 3" className="rounded-md w-full h-auto object-cover row-span-2" />

                    </div>
                    <div className="md:w-1/2 grid grid-cols-2 md:grid-cols-2 gap-4">
                        <img src={story1} alt="story 1" className="rounded-md w-full h-auto object-cover mt-5" />
                        <img src={story2} alt="story 2" className="rounded-md w-full h-auto object-cover" />
                        <img src={story4} alt="story 4" className="rounded-md w-full h-auto object-cover" />
                        <img src={story5} alt="story 5" className="rounded-md w-full h-auto object-cover" />
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row justify-center items-center text-center mb-12 mx-auto w-full space-y-8 md:space-y-0 md:space-x-8">
                <Statistic end={500} title={t("Consultant")} />
                <Statistic end={70} title={t("Projects")} description={t("Published in international SCI and SSCI journals")} />
                <Statistic end={120} title={t("Statistical Revisions")} description={t("Published in local journals")} />
                <Statistic end={96} title={t("Smiling Faces 😊")} />
                <Statistic end={6} title={t("Mean Data Return")} description={t("Days")} />
            </div>
            <div className="text-center mb-12 bg-[#f3f7fa] w-full p-10 lg:mt-[10vh]">
                <h2 className="text-[24px] text-[#004643]">{t('Values')}</h2>
                <div className="flex justify-around mt-8 text-left max-md:flex-col max-md:items-center max-md:gap-4">
                    <div className="w-1/5 max-md:w-4/5 space-y-2">
                        <p className='text-[#9EB1B0] text-[18px]'>01</p>
                        <h3 className="text-xl text-[#004643]">{t('Customer Satisfaction')}</h3>
                        <p className='text-16px text-[#9EB1B0]'>{t("We strive to understand our customers' needs and meet their expectations.")}</p>
                    </div>
                    <div className="w-1/5 max-md:w-4/5 space-y-2">
                        <p className='text-[#9EB1B0] text-[18px]'>02</p>
                        <h3 className="text-xl text-[#004643]">{t('Quality')}</h3>
                        <p className='text-16px text-[#9EB1B0]'>{t('Adding value to our customers by providing services at the highest standards.')}</p>
                    </div>
                    <div className="w-1/5 max-md:w-4/5 space-y-2">
                        <p className='text-[#9EB1B0] text-[18px]'>03</p>
                        <h3 className="text-xl text-[#004643]">{t('Flexibility')}</h3>
                        <p className='text-16px text-[#9EB1B0]'>{t('Offering quick and flexible solutions to meet the changing needs of our customers.')}</p>
                    </div>
                    <div className="w-1/5 max-md:w-4/5 space-y-2">
                        <p className='text-[#9EB1B0] text-[18px]'>04</p>
                        <h3 className="text-xl text-[#004643]">{t('Innovation')}</h3>
                        <p className='text-16px text-[#9EB1B0]'>{t("Supporting our customers' success by continuously developing innovative solutions.")}</p>
                    </div>
                </div>
            </div>
            <div className="container w-4/5 mx-auto px-4 py-12 ">
                <div className="flex flex-col md:flex-row">
                    <div className="md:w-1/2 mb-8 md:mb-0">
                        <h2 className="text-4xl font-bold text-[#004643]">{t('What We Do?')}</h2>
                    </div>
                    <div className="md:w-1/2">
                        <p className="text-lg text-[#004643] text-left font-bold mb-4">{t('MediMetricMinds offers a variety of services to support academic research and clinical studies:')}</p>
                        <div className="space-y-10">
                            <div className="flex flex-col items-start text-left">
                                <img src={ConsultingIcon} alt="Consulting Icon" className="h-6 w-6 mr-2" />
                                <div>
                                    <h3 className="font-bold text-[#004643]">{t('Online Consulting')}:</h3>
                                    <p className='text-[#9EB1B0]'>{t('We provide expert consulting services for clients who need guidance or are starting their research process.')}</p>
                                </div>
                            </div>
                            <div className="flex flex-col items-start text-left">
                                <img src={AnalysisIcon} alt="Analysis Icon" className="h-6 w-6 mr-2" />
                                <div>
                                    <h3 className="font-bold text-[#004643]">{t('Statistical Analysis and Writing Services')}:</h3>
                                    <p className='text-[#9EB1B0]'>{t('We offer expert support from data analysis to writing results.')}</p>
                                </div>
                            </div>
                            <div className="flex flex-col items-start text-left">
                                <img src={GraphicsIcon} alt="Graphics Icon" className="h-6 w-6 mr-2" />
                                <div>
                                    <h3 className="text-[#004643] font-bold">{t('Graphic and Table Creation')}:</h3>
                                    <p className='text-[#9EB1B0]'>{t('We help present research findings more effectively by visualizing them through graphics and tables.')}</p>
                                </div>
                            </div>
                            <div className="flex flex-col items-start text-left">
                                <img src={PlagiarismIcon} alt="Plagiarism Icon" className="h-6 w-6 mr-2" />
                                <div>
                                    <h3 className="text-[#004643] font-bold">{t('Plagiarism Check')}:</h3>
                                    <p className='text-[#9EB1B0]'>{t('We provide professional plagiarism checking services to ensure the originality of articles.')}</p>
                                </div>
                            </div>
                            <div className="flex flex-col items-start text-left">
                                <img src={ReferenceIcon} alt="Reference Icon" className="h-6 w-6 mr-2" />
                                <div>
                                    <h3 className="text-[#004643] font-bold">{t('Reference Management')}:</h3>
                                    <p className='text-[#9EB1B0]'>{t('We organize and update article references using EndNote.')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-3 p-4 mt-[5vh] w-11/12 mx-auto max-md:w-full'>
                    <p className='text-[24px] max-md:text-[16px] text-[#004643]'>
                        {t("Medimetricminds works with an expert team to provide the best service to our clients. Our diverse specialists, including statisticians, clinical experts, and writers, come together to offer customized solutions tailored to our clients' needs.")}
                    </p>
                    <p className='text-[24px] max-md:text-[16px]'>
                        {t("By working with us, you can take the right steps to achieve your academic goals and use your time most efficiently. Feel free to contact us; we would be delighted to discuss how we can assist you.")}
                    </p>
                </div>
            </div>
            <div className="w-4/5 mx-auto flex flex-row text-left mb-8 md:mb-0 max-md:flex-col lg:mt-[5vh]">
                <div className='w-1/3 mx-auto max-md:w-4/5'>
                    <h2 className="text-[16px] text-[#004643] mb-4">{t('VISION & MISSION')}</h2>
                    <h1 className="text-4xl text-[#004643]">{t('Empowering Academic Excellence')}</h1>
                </div>
                <div className='w-1/2 mx-auto max-md:w-4/5'>
                    <p className="mt-4 text-[#004643] text-[24px]">
                        {t('To assist our clients in achieving their academic goals, we offer statistical analysis, data management, and writing services, enabling them to utilize their time and resources most efficiently.')}
                    </p>
                    <p className="mt-4 text-[20px] text-[#9EB1B0]">
                        {t('By providing the best services in the industry and contributing to scientific innovations in the academic world, we aim to be pioneers and earn the trust of our customers.')}
                    </p>
                </div>
            </div>
            <div className="w-4/5 mx-auto lg:mt-[15vh]">
                <div className="text-left flex flex-row w-full mx-auto max-md:flex-col">
                    <div className='w-1/3 mx-auto max-md:w-4/5'>
                        <h2 className="text-[16px] text-[#004643] mb-4">{t('TEAM')}</h2>
                        <h1 className="text-4xl text-[#004643]">{t('Meet the Experts')}</h1>
                    </div>
                    <div className="w-1/2 grid grid-cols-2 max-md:grid-cols-2 gap-4 mt-6 mx-auto max-md:w-full">
                        <div className="flex flex-col items-center max-md:items-start">
                            <img src={EmrahImage} alt="Emrah Şişli" className="rounded-md w-48 h-auto object-cover max-md:te" />
                            <h3 className="font-bold mt-4">Emrah Şişli, MD, Assoc. Prof.</h3>
                            <p className="text-sm">{t('Lead Consultant')}</p>
                            <p className="text-sm">{t('Congenital Cardiac Surgeon')}</p>
                        </div>
                        <div className="flex flex-col items-center mt-[10vh] max-md:items-start">
                            <img src={ArdaImage} alt="Arda Toygartepe" className="rounded-md w-48 h-auto object-cover" />
                            <h3 className="font-bold mt-4">Arda Toygartepe</h3>
                            <p className="text-sm">{t('General Manager')}</p>
                        </div>
                        <div className="flex flex-col items-center max-md:items-start">
                            <img src={OzanImage} alt="Ozan Tuğlu" className="rounded-md w-48 h-auto object-cover" />
                            <h3 className="font-bold mt-4">Ozan Tuğutlu</h3>
                            <p className="text-sm">{t('Operational Manager')}</p>
                        </div>
                        <div className="flex flex-col items-center mt-[10vh] max-md:items-start">
                            <img src={MelisaImage} alt="Melisa Demir" className="rounded-md w-48 h-auto object-cover" />
                            <h3 className="font-bold mt-4">Melisa Demir</h3>
                            <p className="text-sm">{t('Organizational Team Leader')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-4/5 container mx-auto px-4 py-12 max-md:w-4/5 lg:mt-[5vh] lg:mb-[5vh]">
                <div className="flex flex-col md:flex-row">
                    <div className="md:w-1/2 text-left mb-8 md:mb-0">
                        <h2 className="text-[16px] text-primary mb-2">{t('OUR POLICIES')}</h2>
                        <h1 className="text-4xl text-[#004643]">{t('Ensuring Excellence')}</h1>
                    </div>
                    <div className="md:w-1/2 text-left">
                        <p className="text-lg mb-4" style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold', color: '#0D3331' }}>
                            {t('To ensure customer satisfaction and establish a reliable collaborative environment, MediMetricMinds adopts strict privacy policies and business ethics principles. By maintaining the highest quality standards, we continually review and improve our services.')}
                        </p>
                        <p className="text-lg" style={{ fontFamily: 'Arial, sans-serif', color: '#707070' }}>
                            {t('With a flexible refund and cancellation policy, we aim to maximize customer satisfaction. Through strong collaboration and effective communication with our clients, we aim to best meet their needs. These policies reflect the core values of the MediMetricMinds team and our commitment to providing reliable and professional service to our customers.')}
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default About;
