import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import PopupImage from "./Assets/PopupImage.png";
import b1 from "./Biyoistatistikte/1.png";
import b2 from "./Biyoistatistikte/2.png";
import b3 from "./Biyoistatistikte/3.png";
import b4 from "./Biyoistatistikte/4.png";
import b5 from "./Biyoistatistikte/5.png";
import b6 from "./Biyoistatistikte/6.png";
import b7 from "./Biyoistatistikte/7.png";
import "./App.css"
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook

const BlogList = () => {
    const { t } = useTranslation(); // Initializing the translation hook
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(true);
    const blogPosts = [
        {
            id: 1,
            title: t('What Are Statistics and Biostatistics?'),
            date: '22 AUG 2024',
            image: b1,
            content: [
                {
                    heading: t('Introduction'),
                    paragraphs: [
                        t('We live in the age of data, and statistics and biostatistics, which are indispensable tools for scientific research, are crucial for researchers. At MedimetricMinds, we want to explain what these two disciplines are and how they are used.')
                    ],
                },
                {
                    heading: t('What Is Statistics?'),
                    paragraphs: [
                        t('Statistics is a discipline that encompasses the processes of collecting, analyzing, interpreting, and reporting data. For researchers, it is important to evaluate their data with the most appropriate analysis techniques to achieve accurate results. Statistics broadly includes measurements used to estimate data or population parameters. It generally refers to the process of processing and interpreting the data obtained.')
                    ],
                },
                {
                    heading: t('What Is Biostatistics?'),
                    paragraphs: [
                        t('Biostatistics is the application of statistical methods to biology, medicine, and health sciences. In Turkey, biostatistics is usually conducted as a main discipline within medical faculties, creating a strong connection between other medical sciences and biostatistics. This close relationship can sometimes lead to the perception that biostatistics is a completely different field from statistics.')
                    ],
                },
                {
                    heading: t('Differences and Commonalities Between Statistics and Biostatistics'),
                    paragraphs: [
                        t('The difference between statistics and biostatistics arises from the application area of the analyses. While statistics includes general data analysis techniques, biostatistics involves specialized analyses in fields such as medicine and biology. However, fundamental statistical techniques (such as t-tests, ANOVA, correlation analysis) are applied in both fields in the same way. Therefore, a statistician has the knowledge to analyze medical data, but they need detailed guidance on the relationships between the data.')
                    ],
                },
                {
                    heading: t('Biostatistical Analysis: Not a Simple Step'),
                    paragraphs: [
                        t('Many people may view biostatistical analysis as a simple step in the scientific research process. However, this is a misconception. Biostatistical analysis is one of the most critical stages of research and directly affects the reliability and validity of scientific research. Here are some factors explaining why this process is so important:'),
                        t('1. Determining Scientific Value: Researchers rely on biostatistical analyses to determine the scientific value of their work. Using the correct statistical methods ensures the reliability of the results obtained.'),
                        t('2. Organizing Groups: Biostatistics presents complex data groups in a more meaningful and organized manner, which facilitates the interpretation and understanding of the results.'),
                        t('3. Data Collection and Processing: How data is collected and processed directly affects the success of the analysis process. Biostatistics ensures the accuracy of data collection methods and the correct processing of data.'),
                        t('4. Reporting Results: Biostatistical analyses ensure that research results are reported accurately and effectively. This helps the scientific community understand and evaluate both how the findings were obtained and the results themselves.')
                    ],
                },
            ],
        },
        {
            id: 2,
            title: t('What Is SPSS?'),
            date: '23 AUG 2024',
            image: b2,
            content: [
                {
                    heading: t('Introduction to SPSS'),
                    paragraphs: [
                        t('SPSS (Statistical Package for the Social Sciences) is a statistical analysis software developed for social sciences. However, over time, it has become widely used across various scientific fields, including biostatistics, due to its broad data analysis capabilities. SPSS allows researchers to easily analyze complex datasets with its user-friendly interface and powerful analysis features.')
                    ],
                },
                {
                    heading: t('SPSS and Its Use: Importance and Scope in Biostatistics'),
                    paragraphs: [
                        t('SPSS, a leading tool for data analysis in scientific research, holds significant importance in biostatistics. At MedimetricMinds, we aim to share the use and importance of SPSS in biostatistics.')
                    ],
                },
                {
                    heading: t('Uses of SPSS in Biostatistics'),
                    paragraphs: [
                        t('Biostatistics requires data analysis in health and biology fields. SPSS provides a powerful set of tools for conducting these analyses. Here are the areas where SPSS is used in biostatistics:'),
                        t('1. Data Management: SPSS facilitates the management and organization of large datasets. Data cleaning, handling missing values, and data transformation can be performed quickly.'),
                        t('2. Descriptive Statistics: Researchers can use descriptive statistics (mean, median, standard deviation, etc.) to understand the general characteristics of datasets. SPSS automatically performs these calculations and presents them to the user.'),
                        t('3. Hypothesis Testing: Biostatistics involves testing hypotheses. SPSS performs various hypothesis tests, such as t-tests, ANOVA, and chi-square tests, quickly and accurately.'),
                        t('4. Regression Analysis: SPSS enables the examination of relationships between variables through simple and multiple regression analyses. This is crucial for studies like identifying risk factors in health fields.'),
                        t('5. Survival Analysis: SPSS supports survival analyses commonly used in health research. These analyses are used to examine the progression of diseases and the effectiveness of treatments.'),
                        t('6. Visualization: SPSS provides various graphic and plotting tools for data visualization. This helps present results in a clearer and more effective manner.')
                    ],
                },
                {
                    heading: t('Importance of SPSS in Biostatistics'),
                    paragraphs: [
                        t('The importance of SPSS in biostatistics stems from its many advantages:'),
                        t('1. User-Friendly Interface: SPSS offers complex statistical analyses through a user-friendly interface. This allows users with limited biostatistics knowledge to perform effective analyses.'),
                        t('2. Powerful Data Analysis Capacity: SPSS can analyze large datasets quickly and efficiently. This is a significant advantage for researchers working with large databases in health fields.'),
                        t('3. Comprehensive Statistical Toolset: SPSS offers a wide range of analysis options, from descriptive statistics to complex modeling. This helps meet various research needs in biostatistics.'),
                        t('4. Reliability and Validity: SPSS has proven itself over the years in producing reliable and valid results in scientific research. This ensures high reliability in biostatistical analyses.'),
                        t('5. Quality of Tables and Graphs: SPSS provides the capability to create high-quality, easily understandable tables and graphs. This facilitates the visual presentation of biostatistical results in academic papers and theses. Data visualization helps readers quickly grasp key findings and focus on the main points of the study.')
                    ],
                },
                {
                    heading: t('Role of SPSS in Academic Work'),
                    paragraphs: [
                        t('SPSS is a crucial indicator for academic studies and theses. However, researchers today wish to focus more on their research topics rather than just data analysis. In this regard, expert teams proficient in comprehensive data processing programs like SPSS help lighten the researchers\' workload. At MedimetricMinds, we enhance your research with our support in this area, alleviating the burden in biostatistics.'),
                        t('In manuscript writing, effectively presenting biostatistical results is crucial. In scientific papers where word usage is limited, some results need to be highlighted and communicated to the reader efficiently. Therefore, the visual quality of tables and graphs must be high and easily understandable. SPSS provides the tools necessary to meet these requirements and enables effective visualization of your data.')
                    ],
                },
            ],
        },
        {
            id: 3,
            title: t('References: Correct and Effective Use in Scientific Articles'),
            date: '24 AUG 2024',
            image: b3,
            content: [
                {
                    heading: t('Introduction to References'),
                    paragraphs: [
                        t('Scientific articles heavily rely on references to ensure the credibility and validity of the research. However, the format and style of references can vary depending on the requirements of the journal where the article is submitted. The International Committee of Medical Journal Editors (ICMJE) recommends that authors use original sources as much as possible in their most recent guidelines. Therefore, it is important to follow these guidelines when preparing your references. You can find detailed information [here](https://www.icmje.org/icmje-recommendations.pdf).'),
                        t('Additionally, resources such as PubMed and the U.S. National Library of Medicine can help with reference formatting. PubMed provides [formatting examples for different reference types](https://www.nlm.nih.gov/bsd/uniform_requirements.html), and the U.S. National Library of Medicine’s [eBook: Citing Medicine](https://www.ncbi.nlm.nih.gov/books/NBK7256/) offers assistance with citation rules.')
                    ],
                },
                {
                    heading: t('Writing and Managing References'),
                    paragraphs: [
                        t('Authors can manually prepare references following specific guidelines. However, tracking changes in the order of references and the growth of the manuscript can make it challenging to maintain the integrity of the content. To assist with this, various citation management software tools are available. These tools help you organize, track, and generate bibliographies. Some tools, like EndNote, Refworks, Mendeley, and Zotero, offer additional features such as in-text citation plugins, the ability to add full-text PDFs, and collaboration options for sharing bibliographies with co-authors.')
                    ],
                },
                {
                    heading: t('Common Reference Types in Medical Articles'),
                    paragraphs: [
                        t('Common reference types used in medical articles include:'),
                        t('- Journal articles'),
                        t('- Books'),
                        t('- Book chapters'),
                        t('- Posters or oral presentation texts published in conference proceedings'),
                        t('- Web pages'),
                        t('- Theses'),
                        t('Each journal has its own format changes, punctuation rules, and arrangements between sections. The formatting of each section of a reference may include punctuation placement, bold and italic enhancements, alphabetical or sequential ordering of references, and citation styles within the text.')
                    ],
                },
                {
                    heading: t('Understanding and Reading Article References'),
                    paragraphs: [
                        t('Each article has a reference format, and understanding how to read this format is essential for quick access to the article in library indices or on the journal’s website. For example, "INJURY 2016;47(9):1945-1950" indicates the year 2016, volume 47, issue 9, starting page 1945, and ending page 1950. Correct interpretation of this information ensures quick access to the article.'),
                        t('Proper and effective use of references in your scientific article enhances the quality and credibility of your work. Therefore, careful attention to reference preparation and management is crucial.')
                    ],
                },
            ],
        },
        {
            id: 4,
            title: t('Plagiarism: Understanding and Avoiding It'),
            date: '25 AUG 2024',
            image: b4,
            content: [
                {
                    heading: t('What is Plagiarism?'),
                    paragraphs: [
                        t('Plagiarism is a critical issue in the academic and professional writing world. It involves presenting someone else\'s work or ideas as your own. It is important to note that plagiarism is not limited to copying and pasting; it also includes stealing ideas and verbal patterns.')
                    ],
                },
                {
                    heading: t('Definition and Causes of Plagiarism'),
                    paragraphs: [
                        t('The term “plagiarism” is defined in dictionaries as “presenting someone else’s work or ideas as your own.” The primary reasons for plagiarism in medical articles or other academic texts include laziness, procrastination, lack of creativity, understanding, or knowledge about the topic.')
                    ],
                },
                {
                    heading: t('How to Avoid Plagiarism in Medical Writing'),
                    paragraphs: [
                        t('To avoid plagiarism in medical writing, consider the following approaches:'),
                        t('1. Detecting and Rewriting Content: To avoid plagiarism, first identify any potential issues in your text. Then, rephrase the original ideas in your own words while properly citing the original source. The goal of rewriting is to present the idea in your own words while giving appropriate credit to the original author.'),
                        t('2. Plagiarism Checkers: To effectively avoid plagiarism, use “plagiarism checkers.” These tools compare your text with online sources to identify any similarities and highlight matching sections. iThenticate is a widely used tool among these, providing a report that indicates the points of plagiarism and the sources of the plagiarized content. Additionally, it provides the percentage of plagiarism in the text.'),
                        t('3. Paraphrasing Tools: Paraphrasing involves changing the words and sentences of content without altering its original meaning and purpose. You can do this manually or use online paraphrasing tools to help with the process.')
                    ],
                },
                {
                    heading: t('Avoiding Plagiarism with MediMetricMinds'),
                    paragraphs: [
                        t('At MediMetricMinds, we check your medical texts for plagiarism using plagiarism detection software and provide you with a detailed report. Generally, medical journals expect the plagiarism rate to be below 20%. Upon request, we help reduce the similarity rate in your articles to below 20% using paraphrasing tools and our experts’ input.'),
                        t('Avoiding plagiarism is crucial for maintaining credibility and ensuring the originality of your work in both academic and professional settings. Therefore, taking the necessary steps to ensure your texts are free from plagiarism is essential.')
                    ],
                },
            ],
        },
        {
            id: 5,
            title: t('Expert Consulting Services in Statistics and Biostatistics by MedimetricMinds'),
            date: '27 AUG 2024',
            image: b5,
            content: [
                {
                    heading: t('What MedimetricMinds Offers'),
                    paragraphs: [
                        t('At MedimetricMinds, we provide expert consulting services in statistics and biostatistics. From statistical hypothesis testing to complex modeling, we offer comprehensive support across a wide range of areas. Our consulting services are delivered by experienced clinicians who guide you through every stage of your research. Accurate data analysis and interpretation are crucial for the success of your research.')
                    ],
                },
                {
                    heading: t('MedimetricMinds\' SPSS Consulting Services'),
                    paragraphs: [
                        t('At MedimetricMinds, we offer expert consulting services in SPSS and biostatistics. Our services include:'),
                        t('1. Data Analysis and Reporting: We analyze your datasets using SPSS and prepare reports that meet scientific standards.'),
                        t('2. Training and Support: We conduct training sessions on SPSS and biostatistics, helping researchers effectively use these tools.'),
                        t('3. Customized Solutions: We provide customized SPSS solutions tailored to the needs of your research.')
                    ],
                },
                {
                    heading: t('Why Choose MedimetricMinds?'),
                    paragraphs: [
                        t('1. Expert Team: Our team consists of clinicians with academic and professional experience in biostatistics, providing the most accurate analyses.'),
                        t('2. Comprehensive Services: We offer thorough consulting throughout the data collection, analysis, interpretation, and reporting processes.'),
                        t('3. Customized Approach: We provide tailored solutions based on the unique needs of your research.'),
                        t('4. Training and Support: We offer training on statistical methods and ensure researchers understand these processes.')
                    ],
                },
                {
                    heading: t('Consult with Our Experts'),
                    paragraphs: [
                        t('Understanding and interpreting statistical data is crucial. However, over time, this knowledge can become outdated or new information may need to be presented. MedimetricMinds supports you through online courses categorized into three areas to enhance your academic presentations and grasp of results.')
                    ],
                },
                {
                    heading: t('MedimetricMinds Online Consulting Services'),
                    paragraphs: [
                        t('In the rapidly changing modern world, the effectiveness and accuracy of your research are directly linked to the quality of your consulting services. At MedimetricMinds, we offer top-notch online consulting services to provide the support you need in statistics and biostatistics.'),
                        t('Advantages of Online Consulting Services:'),
                        t('1. Easy Access and Quick Solutions: Reach our expert consultants from anywhere with an internet connection, eliminating geographical limitations.'),
                        t('2. Save Time and Resources: Focus on your academic research without the need for physical meetings. We minimize time loss with SPSS and adapt to your busy schedule.'),
                        t('3. Work with Expert Teams: Our team of experts, with years of experience in statistics and biostatistics, provides clear and effective solutions to even the most complex questions.'),
                        t('4. Personalized and Targeted Services: We develop strategies tailored to the specific needs of your research, ensuring you achieve success.')
                    ],
                },
                {
                    heading: t('Conclusion'),
                    paragraphs: [
                        t('SPSS is an indispensable tool in biostatistics. Its user-friendly interface, powerful data analysis capabilities, and comprehensive statistical tools enable researchers to perform even the most complex analyses with ease. Additionally, SPSS\'s high-quality tables and charts enhance the visual and analytical aspects of your academic work. At MedimetricMinds, we are here to provide you with the best consulting services in SPSS and biostatistics. For more information and to benefit from our consulting services, please contact us.'),
                        t('This blog post is designed to highlight MedimetricMinds\' consulting services and provide information about the importance of SPSS in biostatistics. Feel free to visit our website for any questions and needs.')
                    ],
                },
            ],
        },
    ];



    const filteredPosts = blogPosts.filter(post =>
        post.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75 max-md:h-screen">
                    <div className="relative bg-white flex flex-col md:flex-row rounded-lg shadow-lg overflow-hidden w-4/5 md:w-2/3 lg:w-1/2 p-6 max-md:h-3/4 animate-fadeIn">
                        <button
                            onClick={closeModal}
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                        >
                            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div className="md:w-1/2 max-md:mt-3 flex flex-col justify-between max-md:justify-center">
                            <div className="fade-in-up">
                                <h2 className="text-2xl font-bold mb-4">{t('Need a Private Lesson?')}</h2>
                                <p className="text-gray-700 mb-4 max-md:text-left">
                                    {t('If you require personalized guidance to achieve your academic or research goals, we offer private lessons tailored to your needs. Our experts are ready to provide one-on-one support to help you succeed. Contact us today to schedule your private session and get started on the path to excellence.')}
                                </p>
                            </div>
                            <Link to="/contact" onClick={closeModal} className="inline-block bg-teal-500 text-white rounded-md py-2 px-4 hover:bg-teal-600 mt-4 self-start md:self-auto max-md:m-4 fade-in-up">
                                {t('Inquire Now')} →
                            </Link>
                        </div>
                        <div className="md:w-1/2 ml-4">
                            <img src={PopupImage} className="w-full h-full object-cover rounded-md fade-in-up" alt={t('Modal')} />
                        </div>
                    </div>
                </div>
            )}

            <div className="container w-4/5 mx-auto px-4 py-12">
                <div className="flex justify-between items-center mb-8 max-md:flex-col max-md:gap-3">
                    <h1 className="text-4xl font-bold">{t('Blog')}</h1>
                    <div className="relative">
                        <input
                            type="text"
                            placeholder={t('Search')}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="border border-gray-300 rounded-lg py-2 px-4 w-64"
                        />
                        <button className="absolute right-2 top-2 text-teal-500">
                            {t('Search')}
                        </button>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {filteredPosts.map(post => (
                        <div key={post.id} className="bg-white shadow-md rounded-lg overflow-hidden">
                            <img src={post.image} className="w-full h-48 object-cover" alt={post.title} />
                            <div className="p-4">
                                <div className="text-primary text-sm mb-2">{post.date}</div>
                                <h2 className="text-xl text-primary mb-2">{post.title}</h2>
                                <Link to={`/blog/${post.id}`} className="text-teal-500 hover:text-teal-700">{t('Read More')} →</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default BlogList;
