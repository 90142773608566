import React from 'react';
import Footer from './Footer';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import s1 from "./Assets/S1.png";
import s2 from "./Assets/S2.png";
import s3 from "./Assets/S3.png";
import s4 from "./Assets/S4.png";
import s5 from "./Assets/S5.png";
import s6 from "./Assets/S6.png";
import s7 from "./Assets/S7.png";
import s8 from "./Assets/S8.png";
import s9 from "./Assets/S9.png";
import s10 from "./Assets/S10.png";
import s11 from "./Assets/S11.png";
import s12 from "./Assets/S12.png";
import s13 from "./Assets/S13.png";
import s14 from "./Assets/S14.png";

const servicesData = [
    {
        id: 1,
        title: "Work Plan, Work List",
        Image: s1,
        description: "We can help you create a work plan and work list for your scientific research project, which is often neglected but ensures that the work items flow smoothly throughout the study. Make your appointment and simply create your work plan with our expert guidance via an online face-to-face discussion.",
    },
    {
        id: 2,
        title: "Statistical Analysis",
        Image: s2,
        description: "Did you say that your database is ready and the statistical analysis to be applied has been determined? Congratulations! You can get a price and working time quote for statistical analysis services. To do this, we will ask you to fill out a form that we have prepared for you to briefly inform our expert about the purpose of your research along with other relevant information. If you do not want to fill out the form, you can inform our expert about your research through an online conversation. Once you upload your database to the system along with the form, we will get back to you within 24 hours at the latest.",
    },
    {
        id: 3,
        title: "Graphics and Tables",
        Image: s3,
        description: "The presentation of the results of any research is in need to be simplified, summarized, and be much more easy to be understood with tables and graphics. This work increases the readability and understandability of your article with our easy-to-understand and simplified tables. With our infrastructure software, we create content-rich graphics that increase the clarity and presentability of your article.",
    },
    {
        id: 4,
        title: "Visual/Graphical Abstract",
        Image: s4,
        description: "Many international and respected medical journals now demand the creation of a visual/graphical abstract that summarizes the method and content of scientific research as well as important findings that contribute to the literature and ensures that the new understanding is shared with the reader at first glance. You can increase the interest of your article using a content-rich visual/graphical abstract that we can create for you.",
    },
    {
        id: 5,
        title: "Statistical Analysis and Results Section",
        Image: s5,
        description: "Upon your request, we can write the 'results' section of your article and the 'statistical analysis' section of the materials and methods in line with your instructions, in mutual communication. The expert who will write the article will not only be a native English speaker but also an expert who has particular knowledge of the research topic and terminological jargon.",
    },
    {
        id: 6,
        title: "Citation/References",
        Image: s6,
        description: "During the article writing process, keeping minute-to-minute updated references in the text and in the references section simultaneously is not only a time-consuming but also a tiring process. At this stage, we want you to deal with the details of the content of your references and to decide which references to use and which not to use. Let us take care of the references for you using the EndNote.",
    },
    {
        id: 7,
        title: "Pre-Submission Peer-Review",
        Image: s7,
        description: "Do you think the final version of your article is okay? Critical peer review before submitting your article to the journal is called pre-submission peer review and helps you pass the evaluations of the article with less damage by the referees determined by the journal. Our experts who support this issue are familiar with the perspective of journal referees.",
    },
    {
        id: 8,
        title: "Edit/Submit",
        Image: s8,
        description: "We feel and know that you are in a very busy work tempo. We can edit your completed article according to the submission guideline of the target journal you want to submit and submit it to the journal on your behalf. Just simply make a request from us.",
    },
    {
        id: 9,
        title: "Title Page / Cover Letter",
        Image: s9,
        description: "Each article requires a title page and cover letter when being sent to a journal. The title page is requested from the authors in order to ensure that the referees evaluate your article in a healthy and impartial manner. It is aimed to hide in which center and by whom the research was conducted throughout your article text. The 'cover letter' is requested to briefly inform the journal editor that your research findings are not in the process of being evaluated simultaneously by another journal and the important points that your research findings contribute to science. These services are provided on your behalf by our experts.",
    },
    {
        id: 10,
        title: "Plagiarism Check",
        Image: s10,
        description: "Today, many journals and publishers use plagiarism detection software to prevent plagiarism. The similarity index obtained as a result of this check is used as a criterion, and many publishers require the similarity index of the articles they wish to publish to be below 20%. We want to let you know that we are here to support you in this matter. We perform plagiarism checks with multiple licensed software and provide the results to you as a report.",
    },
    {
        id: 11,
        title: "Similarity Index Reduction",
        Image: s11,
        description: "Did your article's similarity index exceed 20% as a result of the plagiarism check? If so, how about using our SIR service to reduce your article's similarity index below 20%?",
    },
    {
        id: 12,
        title: "Translation",
        Image: s12,
        description: "Writing your article in your native language other than English means expressing what you want to convey more effectively. We would like to support you in this matter. You write your article in your own language, and we will translate it into English with expert native translators.",
    },
    {
        id: 13,
        title: "Proofreading",
        Image: s13,
        description: "Did you write your article in English and want to undergo a comprehensive anatomical and expressionist review? We can support you with our proofreading service in this matter.",
    },
    {
        id: 14,
        title: "English Support & IELTS Courses",
        Image: s14,
        description: "As much as the English used in daily life, the English language skills you will use in your academic life are also very important. Every language course has its unique focus areas. Preparing for English proficiency exams with our team will have a positive impact on your academic life. Please contact us for specially prepared free introductory seminars.",
    },
];

const Services = () => {
    const { t } = useTranslation(); // Initialize the useTranslation hook

    return (
        <>
            <div className="container w-4/5 mx-auto px-4 py-12 ">
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold">{t("How We Can Help You")}</h1>
                    <button className="mt-4 px-4 py-2 bg-teal-500 text-white rounded-md hover:bg-teal-600"
                        onClick={() => window.location.href = 'contact'}>
                        {t("Let's Begin")}
                    </button>
                </div>
                <div className="relative">
                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-0.5 bg-gray-300 h-full hidden md:block"></div>
                    {servicesData.map((service, index) => (
                        <div key={service.id} className="flex flex-col md:flex-row items-center mb-12 relative gap-5">
                            {index % 2 === 0 ? (
                                <>
                                    <div className="md:w-1/2 w-full mb-4 md:mb-0 pr-0 md:pr-8">
                                        <img src={service.Image} alt={t(service.title)} className="w-full h-48 object-cover rounded-md" />
                                    </div>
                                    <div className="md:w-1/2 w-full pl-0 md:pl-8">
                                        <div className="relative">
                                            <div className="absolute -left-16 top-1/2 transform -translate-y-1/2 w-12 h-12 bg-primary text-white rounded-full flex items-center justify-center text-xl font-bold hidden md:flex">
                                                {service.id.toString().padStart(2, '0')}
                                            </div>
                                            <h2 className="text-2xl text-[#004643] mb-2">{t(service.title)}</h2>
                                            <p className="text-[#9EB1B0]">{t(service.description)}</p>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="md:w-1/2 w-full mb-4 md:mb-0 pr-0 md:pr-8">
                                        <div className="relative">
                                            <div className="absolute -right-16 top-1/2 transform -translate-y-1/2 w-12 h-12 bg-primary text-white rounded-full flex items-center justify-center text-xl font-bold hidden md:flex">
                                                {service.id.toString().padStart(2, '0')}
                                            </div>
                                            <h2 className="text-2xl text-[#004643] mb-2">{t(service.title)}</h2>
                                            <p className="text-[#9EB1B0]">{t(service.description)}</p>
                                        </div>
                                    </div>
                                    <div className="md:w-1/2 w-full pl-0 md:pl-8">
                                        <img src={service.Image} alt={t(service.title)} className="w-full h-48 object-cover rounded-md" />
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Services;
